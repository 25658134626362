@charset "UTF-8";
/*
@function w($_size, $_base-width: 1440px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width)) * 100vw;
  }
  @return $size;
}

@function s($size) {
  @return strip-unit($size) * 1px;
}

@function sw($_size, $_base-width: 750px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width)) * 100vw;
  }
  @return $size;
}
*/
/* ==========================================================================
 *
 * Utility: Extend - layout
 *
mediaの外で作る必要がある
.base{
	@extend %center
}
========================================================================== */
/*
 * RESET
*/
body {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, blockquote, pre, table, caption, th, td, address, form, fieldset, legend, object, header, footer, section, article, nav, figure, canvas {
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

fieldset, img {
  border: none;
  vertical-align: top;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal;
}

ol, ul, li {
  list-style: none;
}

caption, th, td {
  vertical-align: top;
  text-align: left;
}

h1, h2, h3, h4, h5, h6, small {
  font-weight: normal;
  font-size: 100%;
}

i {
  font-style: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: none;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

a, object, embed {
  outline: none;
}

a:hover, a:active, a:focus {
  outline: none;
}

header, footer, section, article, nav, figure, canvas, main {
  display: block;
}

@media screen and (min-width: 960px) {
  .-sp {
    display: none;
  }
  section, #container {
    will-change: transform;
  }
}
@media screen and (max-width: 960px) {
  .-pc {
    display: none;
  }
}
.about-member-img img {
  pointer-events: none;
  user-select: none;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: none;
}

#trans_layer_1 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: #eee;
  will-change: transform;
}
#trans_layer_1.-trans1 {
  height: 100%;
  transition: height 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}
#trans_layer_1.-trans1.-trans2 {
  top: 0;
  height: 0%;
  transition: height 0.4s cubic-bezier(0.86, 0, 0.07, 1);
}

#trans_layer_2 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: #fff;
  will-change: transform;
}
#trans_layer_2.-trans2 {
  height: 100%;
  opacity: 0;
  transition: height 0.4s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.4s 0.3s ease-out;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

body {
  font-size: 16px;
  line-height: 1;
  background: #F5F5F5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: YakuHanJP, YuGothic, "Yu Gothic Medium", "Yu Gothic", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, Verdana, Meiryo, sans-serif;
}

.-font {
  font-family: nimbus-sans, sans-serif;
}
.-font * {
  font-family: nimbus-sans, sans-serif;
}

#container {
  background: #F5F5F5;
}

@media screen and (min-width: 960px) {
  [data-device=sp] {
    display: none !important;
  }
}
@media screen and (max-width: 960px) {
  [data-device=pc] {
    display: none !important;
  }
}
@media screen and (min-width: 960px) {
  .hov-image {
    cursor: none;
    overflow: hidden;
  }
  .hov-image img {
    transition: ease-out 0.4s transform;
  }
  .hov-image:hover img {
    transform: scale(1.1);
  }
}
.slick-prev, .slick-next {
  cursor: none;
}

body, html {
  height: 100%;
}

img {
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
}

.prlx {
  transform: translate(0, 40px);
  opacity: 0;
  transition: all 0.6s ease-out, opacity 0.6s linear;
}
.prlx.-show {
  transform: translate(0, 0);
  opacity: 1;
}

@media screen and (min-width: 960px) {
  #cursor {
    width: 20px;
    height: 20px;
    background: #01A0E9;
    background: #000;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    top: -400px;
    left: -400px;
    display: none;
  }
  #cursor_scrl {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    top: -400px;
    left: -400px;
  }
  #cursor_scrl span.-left:nth-child(1) {
    opacity: 1;
    right: 0.78125vw;
    transition: all 0.2s ease-out;
  }
  #cursor_scrl span.-left:nth-child(1):before {
    transform: rotate(13deg);
  }
  #cursor_scrl span.-left:nth-child(1):after {
    transform: rotate(-13deg);
  }
  #cursor_scrl span.-right:nth-child(2) {
    opacity: 1;
    left: 0.78125vw;
    transition: all 0.2s ease-out;
  }
  #cursor_scrl span.-right:nth-child(2):before {
    transform: rotate(13deg);
  }
  #cursor_scrl span.-right:nth-child(2):after {
    transform: rotate(-13deg);
  }
  #cursor_scrl span:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0vw;
    width: 3.125vw;
    height: 1.5625vw;
    opacity: 0;
  }
  #cursor_scrl span:nth-child(1):before {
    position: absolute;
    left: 0vw;
    top: 0.78125vw;
    height: 1px;
    width: 3.125vw;
    background: #000;
    content: "";
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    transform-origin: 0% 0;
  }
  #cursor_scrl span:nth-child(1):after {
    position: absolute;
    left: 0vw;
    top: 0.78125vw;
    height: 1px;
    width: 3.125vw;
    background: #000;
    content: "";
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    transform-origin: 0% 0;
  }
  #cursor_scrl span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0vw;
    width: 3.125vw;
    height: 1.5625vw;
    opacity: 0;
  }
  #cursor_scrl span:nth-child(2):before {
    position: absolute;
    left: 0vw;
    top: 0.78125vw;
    height: 1px;
    width: 3.125vw;
    background: #000;
    content: "";
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    transform-origin: 100% 0;
  }
  #cursor_scrl span:nth-child(2):after {
    position: absolute;
    left: 0vw;
    top: 0.78125vw;
    height: 1px;
    width: 3.125vw;
    background: #000;
    content: "";
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
    transform-origin: 100% 0;
  }
}
/*
@keyframes xxx {
  0%   {}

  50%  {}

  100% {}
  AAA
}
*/
@media screen and (min-width: 960px) {
  .about {
    background: #F5F5F5;
    padding-top: 180px;
  }
  .about h1 {
    font-size: 94px;
    text-align: left;
    font-weight: 700;
    width: 900px;
    margin: 0 auto;
    margin-bottom: 27px;
    line-height: 1.329787234;
    letter-spacing: 1px;
  }
  .about h1 br {
    display: none;
  }
  .about .about-h1-text {
    width: 900px;
    margin: 0 auto;
  }
  .about .about-h1-text p {
    font-weight: bold;
    text-align: left;
    line-height: 1.8648648649;
    font-size: 37px;
    letter-spacing: 1px;
    padding-bottom: 58px;
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, Verdana, Meiryo, sans-serif;
  }
  .about .about_about {
    width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .about .about_word {
    position: relative;
    margin-top: 15px;
    transform: scale(0.92);
    margin-bottom: 80px;
  }
  .about .about_word .overlap {
    position: absolute;
    top: 204px;
    left: 122px;
    height: 32px;
    opacity: 0;
    transition: opacity linear 0.8s;
  }
  .about .about_word .overlap.-show {
    opacity: 1;
  }
  .about .about_word ul {
    display: flex;
    justify-content: center;
    margin-right: 112px;
  }
  .about .about_word ul li {
    display: inline-block;
    width: 236px;
    height: 236px;
    line-height: 236px;
    text-align: center;
    font-size: 15px;
    margin: 0 -72px;
    position: relative;
  }
  .about .about_word ul li:nth-child(1), .about .about_word ul li:nth-child(3) {
    margin-top: 160px;
  }
  .about .about_word ul li svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
  }
  .about .about_word ul li circle {
    fill: transparent;
    stroke: #000;
    stroke-width: 1;
    stroke-dasharray: 0 760;
  }
  .about .about_word ul li p {
    transform: translate(0, 10px);
    opacity: 0;
    transition: all cubic-bezier(0.23, 1, 0.32, 1) 0.8s 0.6s;
  }
  .about .about_word ul li:nth-child(1) p {
    position: relative;
    right: 10px;
  }
  .about .about_word ul li:nth-child(3) p {
    position: relative;
    right: -10px;
  }
  .about .about_word ul li.-show p {
    transform: translate(0, 0);
    opacity: 1;
  }
  .about .about_word ul li.-show circle {
    animation: circle 1.2s 1 cubic-bezier(0.23, 1, 0.32, 1);
    animation-fill-mode: forwards;
  }
  .about .about-message {
    margin-bottom: 190px;
  }
  .about .about-message p {
    text-align: left;
    line-height: 2.0555555556;
    font-size: 18px;
  }
  .about .about-message .about-message-text {
    margin-bottom: 55px;
    text-align: left;
  }
  .about .about-message .about-message-name {
    text-align: left;
  }
  .about h2 {
    font-size: 15px;
    text-align: center;
    margin-bottom: 103px;
  }
  .about .about-client {
    padding-bottom: 100px;
  }
  .about .about-client ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 900px;
    margin: 0 auto;
  }
  .about .about-client ul li {
    display: inline-block;
    margin: 0 30px 40px;
    width: 150px;
    height: 80px;
    position: relative;
  }
  .about .about-client ul li img {
    width: 100%;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .about .about-client ul span {
    display: inline-block;
    width: 150px;
    margin: 0 30px;
  }
  .about .about-member h3 {
    text-align: center;
    margin-bottom: 6.25vw;
    padding-top: 6.25vw;
  }
  .about .about-member ul.about-member-officer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 89.53125vw;
    max-width: 1146px;
    margin: 0 auto;
  }
  .about .about-member ul.about-member-officer li {
    display: inline-block;
    width: 24.609375vw;
    max-width: 315px;
    margin-bottom: 88px;
  }
  .about .about-member ul.about-member-staff {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 89.53125vw;
    max-width: 1146px;
    margin: 0 auto;
  }
  .about .about-member ul.about-member-staff li {
    display: inline-block;
    width: 32.421875vw;
    max-width: 415px;
    margin-bottom: 40px;
  }
  .about .about-member ul.about-member-staff li:nth-child(3n) {
    width: 24.609375vw;
    max-width: 315px;
  }
  .about .about-member ul.about-member-staff li.about-detail {
    cursor: none;
    transition: 0.2s ease-out all;
  }
  .about .about-member ul.about-member-staff li.about-detail:hover {
    opacity: 0.8;
  }
  .about .about-member ul.about-member-staff span {
    display: inline-block;
    width: 18.75vw;
    max-width: 240px;
  }
  .about .about-member ul.-column2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 89.53125vw;
    max-width: 1146px;
    margin: 0 auto;
  }
  .about .about-member ul.-column2 li {
    display: inline-block;
    width: 44.765625vw;
    max-width: 573px;
    padding-right: 100px;
    margin-bottom: 40px;
  }
  .about .about-member .about-member-img {
    width: 24.609375vw;
    max-width: 315px;
    position: relative;
    margin-bottom: 30px;
  }
  .about .about-member .about-member-img img {
    width: 100%;
  }
  .about .about-member .about-member-img img:nth-child(2) {
    width: 1.5625vw;
    position: absolute;
    bottom: 0.78125vw;
    right: 0.78125vw;
  }
  .about .about-member .about-member-seimei {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 10px;
  }
  .about .about-member .about-member-name {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .about .about-member .about-member-position {
    font-size: 10px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .about .about-member .about-member-position br {
    display: none;
  }
  .about .about-member .about-member-text {
    font-size: 13px;
    line-height: 2;
    margin-bottom: 20px;
  }
  .about .about-member .about-member-link {
    margin-bottom: 20px;
  }
  .about .about-member .about-member-link a {
    height: 15px;
    background-color: #fff;
    font-size: 10px;
    line-height: 15px;
    padding: 0 5px;
    display: inline-block;
    color: #000;
    text-decoration: none;
  }
  .about .about-member .about-member-link a:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .about .about-member .about-member-sns a {
    height: 15px;
    background-color: #fff;
    font-size: 10px;
    line-height: 15px;
    padding: 0 5px;
    display: inline-block;
    color: #000;
    text-decoration: none;
    margin-right: 6px;
  }
  .about .about-member .about-member-sns a:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .about_layer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;
  }
  .about_layer .about_layer_image {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 49.21875vw;
    max-width: 1260px;
  }
  .about_layer .about_layer_image img {
    width: 49.21875vw;
    max-width: 1260px;
    margin-bottom: 5vh;
  }
  .about_layer .about_layer_image .about-layer-seimei {
    font-size: 2.5vh;
    color: #fff;
    margin-bottom: 1.25vh;
  }
  .about_layer .about_layer_image .about-layer-name {
    font-size: 1.75vh;
    margin-bottom: 1.25vh;
    color: #fff;
  }
  .about_layer .about_layer_image .about-layer-position {
    font-size: 1.25vh;
    margin-bottom: 3vh;
    color: #fff;
  }
  .about_layer .about_layer_image .about-layer-description {
    font-size: 1.25vh;
    color: #fff;
    line-height: 2;
  }
  .about_layer .about_layer_close {
    width: 40px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 60px;
    cursor: pointer;
  }
  .about_layer .about_layer_close:before {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #fff;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .about_layer .about_layer_close:after {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #fff;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
  .about_layer .about_layer_close:hover:before {
    transform: rotate(-25deg);
  }
  .about_layer .about_layer_close:hover:after {
    transform: rotate(25deg);
  }
}
@keyframes circle {
  0% {
    stroke-dasharray: 0 760;
  }
  99.9%, to {
    stroke-dasharray: 760 760;
  }
}
@media screen and (max-width: 960px) {
  .about {
    background: #F5F5F5;
    padding-top: 45.6vw;
  }
  .about h1 {
    margin-left: 10.6666666667vw;
    font-size: 12vw;
    line-height: 1.4;
    text-align: left;
    font-weight: 700;
    margin-bottom: 9.3333333333vw;
  }
  .about .about-h1-text p {
    font-weight: bold;
    text-align: left;
    line-height: 2;
    font-size: 4.5vw;
    margin-left: 10.6666666667vw;
  }
  .about .about_word {
    position: relative;
    margin-bottom: 20vw;
  }
  .about .about_word .overlap {
    position: absolute;
    top: 28.5333333333vw;
    left: 46.1333333333vw;
    width: 8vw;
    opacity: 0;
    transition: opacity linear 0.8s;
  }
  .about .about_word .overlap.-show {
    opacity: 1;
  }
  .about .about_word ul {
    display: flex;
    justify-content: center;
  }
  .about .about_word ul li {
    display: inline-block;
    width: 36.2666666667vw;
    height: 36.2666666667vw;
    line-height: 36.2666666667vw;
    text-align: center;
    border-radius: 50%;
    font-size: 2.6666666667vw;
    margin: 0 -11.2vw;
    /*&:nth-child(1),&:nth-child(3){
    	margin-top: 160px;
    }*/
    position: relative;
  }
  .about .about_word ul li:nth-child(1), .about .about_word ul li:nth-child(3) {
    margin-top: 22.1333333333vw;
  }
  .about .about_word ul li svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }
  .about .about_word ul li circle {
    fill: transparent;
    stroke: #000;
    stroke-width: 1;
    stroke-dasharray: 0 760;
  }
  .about .about_word ul li p {
    transform: translate(0, 10px);
    opacity: 0;
    transition: all cubic-bezier(0.23, 1, 0.32, 1) 0.8s 0.6s;
  }
  .about .about_word ul li:nth-child(1) p {
    position: relative;
    right: 2vw;
  }
  .about .about_word ul li:nth-child(3) p {
    position: relative;
    right: -2vw;
  }
  .about .about_word ul li.-show p {
    transform: translate(0, 0);
    opacity: 1;
  }
  .about .about_word ul li.-show circle {
    animation: circle 1.2s 1 cubic-bezier(0.23, 1, 0.32, 1);
    animation-fill-mode: forwards;
  }
  .about .about-message {
    margin-bottom: 32vw;
  }
  .about .about-message p {
    width: auto;
    margin: 0 10.5333333333vw;
    text-align: center;
    line-height: 1.8333333333;
    font-size: 3.2vw;
  }
  .about .about-message .about-message-text {
    margin-bottom: 10.6666666667vw;
    text-align: left;
  }
  .about .about-message .about-message-name {
    text-align: left;
    font-size: 3.2vw;
  }
  .about h2 {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 21.3333333333vw;
  }
  .about .about-client {
    padding-bottom: 21.3333333333vw;
  }
  .about .about-client ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .about .about-client ul li {
    display: inline-block;
    margin: 0;
    width: 48vw;
    text-align: center;
    margin-bottom: 5vw;
  }
  .about .about-client ul li.-nomargin {
    margin-bottom: 0;
  }
  .about .about-client ul li img {
    width: 30vw;
  }
  .about .about-client ul span {
    display: inline-block;
    width: 48vw;
  }
  .about .about-member h3 {
    font-size: 4vw;
    text-align: left;
    margin-left: 10.6666666667vw;
    margin-bottom: 10.6666666667vw;
  }
  .about .about-member ul.about-member-officer {
    display: block;
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
    margin: 0 auto;
    padding-bottom: 3.2vw;
  }
  .about .about-member ul.about-member-officer li {
    margin-left: 10.6666666667vw;
    display: inline-block;
    width: auto;
    margin-bottom: 16.5333333333vw;
  }
  .about .about-member ul.about-member-officer .about-member-name {
    font-size: 5.3333333333vw;
    margin-bottom: 5.3333333333vw;
  }
  .about .about-member ul.about-member-officer .about-member-position {
    font-size: 2.6666666667vw;
    line-height: 1.2;
    margin-bottom: 5.3333333333vw;
  }
  .about .about-member ul.about-member-officer .about-member-text {
    font-size: 3.2vw;
    line-height: 2;
    margin-bottom: 5.3333333333vw;
    padding-right: 10.6666666667vw;
  }
  .about .about-member ul.about-member-officer .about-member-sns a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
    margin-right: 1.6vw;
  }
  .about .about-member ul.about-member-officer .about-member-link a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
    margin-bottom: 5.3333333333vw;
  }
  .about .about-member ul.about-member-staff {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 78.6666666667vw;
    margin: 0 auto;
    padding-bottom: 21.3333333333vw;
  }
  .about .about-member ul.about-member-staff li {
    width: 34.6666666667vw;
    margin-bottom: 10.6666666667vw;
  }
  .about .about-member ul.about-member-staff span {
    width: 34.6666666667vw;
  }
  .about .about-member ul.about-member-staff .about-member-img {
    width: 34.6666666667vw;
    position: relative;
    margin-bottom: 4vw;
  }
  .about .about-member ul.about-member-staff .about-member-img img {
    width: 100%;
  }
  .about .about-member ul.about-member-staff .about-member-img img:nth-child(2) {
    width: 4vw;
    position: absolute;
    bottom: 1.3333333333vw;
    right: 1.3333333333vw;
  }
  .about .about-member ul.about-member-staff .about-member-seimei {
    font-size: 4vw;
    line-height: 1.1;
    margin-bottom: 2.6666666667vw;
  }
  .about .about-member ul.about-member-staff .about-member-name {
    font-size: 3.2vw;
    line-height: 1.2;
    margin-bottom: 2.6666666667vw;
  }
  .about .about-member ul.about-member-staff .about-member-position {
    font-size: 2vw;
    line-height: 1.2;
    padding-right: 5vw;
  }
  .about .about-member ul.about-member-staff .about-member-text {
    font-size: 3.2vw;
    line-height: 2;
    padding-right: 10.6666666667vw;
  }
  .about .about-member ul.about-member-staff .about-member-sns a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
    margin-right: 1.6vw;
  }
  .about .about-member ul.about-member-staff .about-member-link {
    margin-top: 3vw;
  }
  .about .about-member ul.about-member-staff .about-member-link a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
  }
  .about .about-member ul.-column2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
    margin: 0 auto;
  }
  .about .about-member ul.-column2 li {
    margin-left: 10.6666666667vw;
    display: inline-block;
    width: auto;
    margin-bottom: 23.4666666667vw;
  }
  .about .about-member ul.-column2 .about-member-name {
    font-size: 3.2vw;
    margin-bottom: 5.3333333333vw;
  }
  .about .about-member ul.-column2 .about-member-seimei {
    font-size: 4vw;
    line-height: 1.1;
    margin-bottom: 2.6666666667vw;
  }
  .about .about-member ul.-column2 .about-member-position {
    font-size: 2.1333333333vw;
    line-height: 1.2;
    margin-bottom: 5.3333333333vw;
  }
  .about .about-member ul.-column2 .about-member-text {
    font-size: 2.6666666667vw;
    line-height: 2;
    margin-bottom: 5.3333333333vw;
    padding-right: 10.6666666667vw;
  }
  .about .about-member ul.-column2 .about-member-sns a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
    margin-right: 1.6vw;
  }
  .about .about-member ul.-column2 .about-member-link a {
    color: #000;
    text-decoration: none;
    height: 4vw;
    background: #fff;
    font-size: 2.6666666667vw;
    line-height: 4vw;
    padding: 0 1.3333333333vw;
    display: inline-block;
    margin-bottom: 5.3333333333vw;
  }
  .about_layer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;
  }
  .about_layer .about_layer_image {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78.6666666667vw;
    /*
    p {
        font-size: sw(10px);
        margin-top: sw(10px);
        color: #fff;
        font-weight: bold;
    }*/
  }
  .about_layer .about_layer_image img {
    height: auto;
    width: 78.6666666667vw;
    max-width: unset;
    margin-bottom: 6.6666666667vw;
  }
  .about_layer .about_layer_image .about-layer-seimei {
    font-size: 5.3333333333vw;
    margin-bottom: 2.6666666667vw;
    color: #fff;
  }
  .about_layer .about_layer_image .about-layer-name {
    font-size: 3.7333333333vw;
    margin-bottom: 4.2666666667vw;
    color: #fff;
  }
  .about_layer .about_layer_image .about-layer-position {
    font-size: 2.6666666667vw;
    margin-bottom: 6.6666666667vw;
    color: #fff;
  }
  .about_layer .about_layer_image .about-layer-description {
    font-size: 2.6666666667vw;
    line-height: 2;
    margin-bottom: 2.6666666667vw;
    color: #fff;
  }
  .about_layer .about_layer_close {
    width: 10.6666666667vw;
    height: 5.3333333333vw;
    display: inline-block;
    position: absolute;
    top: 12vw;
    right: 8vw;
    cursor: pointer;
  }
  .about_layer .about_layer_close:before {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #fff;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .about_layer .about_layer_close:after {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #fff;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
}
@media screen and (min-width: 960px) {
  .careers {
    background: #F5F5F5;
    padding-top: 17.890625vw;
    /*
    h2{
    	font-size:12px;
    	text-align: center;
    	margin-bottom: 70px;
    }*/
  }
  .careers .careers_visual {
    /*
    height:282px;
    background:url(../img/careers_visual.png) no-repeat center;
    background-size:cover;
    */
    padding-left: 53.125vw;
    margin-bottom: 118px;
  }
  .careers .careers_visual .slick-list {
    overflow: visible;
  }
  .careers .careers_visual .careers_visual-element {
    padding-right: 6.25vw;
    position: relative;
    height: 22.03125vw;
  }
  .careers .careers_visual .careers_visual-element img.-type1 {
    width: 31.25vw;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .careers .careers_visual .careers_visual-element img.-type2 {
    width: 22.03125vw;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .careers h1 {
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 70px;
  }
  .careers .careers-h1-text p {
    text-align: center;
    line-height: 2;
    font-size: 15px;
    padding-bottom: 127px;
  }
  .careers .careers-list {
    width: 76.5625vw;
    margin: 0 auto 80px;
    border-top: 1px solid #000;
  }
  .careers .careers-list ul li {
    cursor: pointer;
    position: relative;
    min-height: 160px;
    width: 100%;
    border-bottom: 1px solid #000;
  }
  .careers .careers-list ul li .careers-list-name {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 40px;
    width: 31.25vw;
    position: absolute;
    top: 66px;
  }
  .careers .careers-list ul li .careers-list-name br {
    display: none;
  }
  .careers .careers-list ul li .careers-list-text {
    padding-top: 66px;
    padding-bottom: 66px;
    font-size: 14px;
    width: 37.65625vw;
    margin-left: 25.9375vw;
    line-height: 1.8;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in;
  }
  .careers .careers-list ul li.-show .careers-list-text {
    max-height: 2000px;
    opacity: 1;
  }
  .careers .careers-list ul li.-show .careers-list-btn:after {
    height: 0px;
    top: 10px;
  }
  .careers .careers-list ul li:hover .careers-list-btn:after {
    height: 10px;
    top: 5px;
  }
  .careers .careers-list ul li .careers-list-btn {
    position: absolute;
    right: 0;
    top: 70px;
    height: 20px;
    width: 40px;
    display: block;
    cursor: pointer;
  }
  .careers .careers-list ul li .careers-list-btn:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 40px;
    height: 1px;
    background: #000;
  }
  .careers .careers-list ul li .careers-list-btn:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 20px;
    width: 1px;
    height: 20px;
    background: #000;
    transition: all 0.5s ease-in-out;
  }
  .careers .careers-essential {
    width: 76.5625vw;
    margin: 0 auto 140px;
    position: relative;
  }
  .careers .careers-essential h3 {
    font-weight: 700;
    font-size: 15px;
    position: absolute;
    top: 0;
  }
  .careers .careers-essential dl {
    margin-left: 25.9375vw;
  }
  .careers .careers-essential dl dt {
    float: left;
    clear: both;
    width: 5.46875vw;
    margin-right: 7.8125vw;
    font-size: 14px;
    line-height: 2;
  }
  .careers .careers-essential dl dd {
    float: left;
    width: 31.25vw;
    font-size: 14px;
    line-height: 2;
    margin-bottom: 22px;
  }
  .careers .careers-essential dl:after {
    content: "";
    display: block;
    clear: both;
  }
  .careers .careers-contact {
    width: auto;
    margin: 0 4.53125vw;
    background: #fff;
    text-align: center;
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .careers .careers-contact .careers-contact-lead {
    font-size: 12px;
    margin-bottom: 52px;
  }
  .careers .careers-contact .careers-contact-address a {
    color: #000;
    font-weight: bold;
    font-size: 22px;
    text-decoration: none;
  }
}
@media screen and (max-width: 960px) {
  .careers {
    background: #F5F5F5;
    padding-top: 35vw;
    /*
    .careers_visual{
    	height:sw(282px);
    	background:url(../img/careers_visual.png) no-repeat 31%;
    	background-size:cover;
    	margin-bottom: sw(143px);
    }*/
    /*
    h2{
    	font-size:sw(12px);
    	text-align: center;
    	margin-bottom: 15vw;
    }*/
  }
  .careers .careers_visual {
    /*
    height:282px;
    background:url(../img/careers_visual.png) no-repeat center;
    background-size:cover;
    */
    width: 100%;
    overflow: hidden;
    margin-bottom: 70px;
  }
  .careers .careers_visual .slick-list {
    overflow: visible;
  }
  .careers .careers_visual .careers_visual-element {
    position: relative;
    height: 75.2vw;
  }
  .careers .careers_visual .careers_visual-element img.-type1 {
    width: 100vw;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .careers .careers_visual .careers_visual-element img.-type2 {
    width: 100vw;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .careers h1 {
    font-size: 5.8666666667vw;
    text-align: center;
    font-weight: 700;
    margin-bottom: 15vw;
  }
  .careers .careers-h1-text p {
    text-align: center;
    line-height: 2.333333;
    font-size: 4vw;
    padding-bottom: 15vw;
  }
  .careers .careers-list {
    width: 78.4vw;
    margin: 0 auto 21.3333333333vw;
    border-top: 1px solid #000;
  }
  .careers .careers-list ul li {
    cursor: pointer;
    position: relative;
    min-height: 42.6666666667vw;
    width: 100%;
    border-bottom: 1px solid #000;
  }
  .careers .careers-list ul li .careers-list-name {
    font-size: 4.5vw;
    line-height: 1.4;
    margin-right: 21.3333333333vw;
    margin-bottom: 10.6666666667vw;
    width: auto;
    position: absolute;
    top: 21.3333333333vw;
  }
  .careers .careers-list ul li .careers-list-name span {
    display: block;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .careers .careers-list ul li .careers-list-text {
    padding-top: 35.2vw;
    padding-bottom: 0vw;
    font-size: 4vw;
    width: auto;
    line-height: 2;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }
  .careers .careers-list ul li.-show .careers-list-text {
    padding-top: 45.8666666667vw;
    padding-bottom: 17.6vw;
    max-height: 640vw;
    opacity: 1;
  }
  .careers .careers-list ul li.-show .careers-list-btn:after {
    height: 0px;
    top: 2.6666666667vw;
  }
  .careers .careers-list ul li .careers-list-btn {
    position: absolute;
    right: 0;
    top: 18.6666666667vw;
    height: 5.3333333333vw;
    width: 10.6666666667vw;
    display: block;
    cursor: pointer;
  }
  .careers .careers-list ul li .careers-list-btn:before {
    content: "";
    position: absolute;
    top: 2.6666666667vw;
    left: 0;
    width: 10.6666666667vw;
    height: 1px;
    background: #000;
  }
  .careers .careers-list ul li .careers-list-btn:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 5.3333333333vw;
    width: 1px;
    height: 5.3333333333vw;
    background: #000;
    transition: all 0.5s ease-in-out;
  }
  .careers .careers-essential {
    width: auto;
    margin: 0 10.6666666667vw 10.1333333333vw;
    position: relative;
  }
  .careers .careers-essential h3 {
    font-weight: 700;
    font-size: 4vw;
    margin-bottom: 9.3333333333vw;
  }
  .careers .careers-essential dl dt {
    float: left;
    clear: both;
    width: 22.1333333333vw;
    font-size: 3.4666666667vw;
    line-height: 2;
  }
  .careers .careers-essential dl dd {
    float: left;
    width: 56.5333333333vw;
    font-size: 3.2vw;
    line-height: 1.8333;
    margin-bottom: 5.8666666667vw;
  }
  .careers .careers-essential dl:after {
    content: "";
    display: block;
    clear: both;
  }
  .careers .careers-contact {
    width: auto;
    margin: 0 10.6666666667vw;
    background: #fff;
    text-align: center;
    padding-top: 12.2666666667vw;
    padding-bottom: 28.2666666667vw;
  }
  .careers .careers-contact .careers-contact-lead {
    font-size: 3.2vw;
    line-height: 1.5;
    margin: 0 12.2666666667vw 16vw;
  }
  .careers .careers-contact .careers-contact-address a {
    color: #000;
    font-weight: bold;
    font-size: 5.8666666667vw;
    text-decoration: none;
  }
}
@media screen and (min-width: 960px) {
  .contact {
    background: #F5F5F5;
  }
  .contact .contact_visual {
    background: url(../img/contact_bg.png) top center;
    background-size: cover;
    height: 80vh;
    margin-bottom: 80px;
  }
  .contact .contact-company {
    width: 76.5625vw;
    margin: 0 auto 140px;
    position: relative;
  }
  .contact .contact-company dl {
    margin-left: 0px;
  }
  .contact .contact-company dl dt {
    float: left;
    clear: both;
    width: 12.96875vw;
    margin-right: 0px;
    font-size: 14px;
    line-height: 2;
  }
  .contact .contact-company dl dd {
    float: left;
    width: 31.25vw;
    font-size: 14px;
    line-height: 2;
    margin-bottom: 22px;
  }
  .contact .contact-company dl:after {
    content: "";
    display: block;
    clear: both;
  }
  .contact .contact-map {
    height: 555px;
    margin: 0 4.53125vw 10px;
  }
  .contact .contact-map > div {
    width: 100%;
    height: 555px;
  }
  .contact .contact-map-link {
    font-size: 10px;
    line-height: 15px;
    height: 15px;
    margin-left: 4.53125vw;
    padding: 0 10px;
    display: inline-block;
    background-color: #fff;
  }
  .contact .contact-map-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
@media screen and (max-width: 960px) {
  .contact {
    background: #F5F5F5;
  }
  .contact .contact_visual {
    background: url(../img/contact_bg.png) center;
    background-size: cover;
    height: 80vh;
    margin-bottom: 16.5333333333vw;
  }
  .contact .contact-company {
    width: auto;
    margin: 0 10.6666666667vw;
    position: relative;
  }
  .contact .contact-company dl {
    margin-left: 0px;
  }
  .contact .contact-company dl dt {
    float: left;
    clear: both;
    width: 22.1333333333vw;
    margin-right: 0px;
    font-size: 3.4666666667vw;
    line-height: 2;
  }
  .contact .contact-company dl dd {
    float: left;
    width: 56.5333333333vw;
    font-size: 3.4666666667vw;
    line-height: 2;
    margin-bottom: 5.8666666667vw;
  }
  .contact .contact-company dl:after {
    content: "";
    display: block;
    clear: both;
  }
  .contact .contact-map {
    width: 78.6666666667vw;
    height: 78.6666666667vw;
    margin: 0 auto 0.78125vw;
  }
  .contact .contact-map > div {
    width: 78.6666666667vw;
    height: 78.6666666667vw;
  }
  .contact .contact-map-link {
    font-size: 2.6666666667vw;
    line-height: 4vw;
    height: 4vw;
    margin-left: 10.6666666667vw;
    padding: 0 2.6666666667vw;
    display: inline-block;
    background-color: #fff;
  }
}
@media screen and (min-width: 960px) {
  .detail {
    background: #F5F5F5;
  }
  .detail .detail_visual {
    height: 56.25vw;
    margin-bottom: 80px;
  }
  .detail .detail_title {
    width: 648px;
    margin: 0 auto 60px;
  }
  .detail .detail_title h1 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 11px;
  }
  .detail .detail_title h2 {
    font-size: 16px;
    line-height: 2;
  }
  .detail .detail_text {
    width: 648px;
    margin: 0 auto 81px;
  }
  .detail .detail_text h3 {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  .detail .detail_text p {
    font-size: 14px;
    line-height: 2;
  }
  .detail iframe {
    width: 86.40625vw;
    height: 48.59375vw;
    margin: 0 auto 10px;
    display: block;
  }
  .detail .detail_image {
    width: 86.40625vw;
    margin: 0 auto 68px;
  }
  .detail .detail_image img {
    margin-bottom: 10px;
    width: 100%;
  }
  .detail .detail_image p {
    color: #777777;
    font-size: 11px;
    line-height: 1.8;
  }
  .detail .detail-list {
    padding: 0 0 0 4.53125vw;
    margin-bottom: 80px;
    overflow: hidden;
  }
  .detail .detail-list .slick-list {
    overflow: visible;
  }
  .detail .detail-list img {
    width: auto;
    height: 40.625vw;
    margin-right: 3.125vw;
    margin-bottom: 10px;
  }
  .detail .detail-list p {
    color: #777777;
    font-size: 10px;
  }
  .detail .detail-list .slick-prev {
    position: absolute;
    top: 0px;
    height: 40.625vw;
    width: 10%;
    z-index: 10;
    left: 0;
    opacity: 0;
  }
  .detail .detail-list .slick-next {
    position: absolute;
    top: 0px;
    right: 0;
    height: 40.625vw;
    width: 10%;
    z-index: 10;
    opacity: 0;
  }
  .detail .detail_more {
    width: 648px;
    margin: 0 auto 120px;
  }
  .detail .detail_more p.detail_more_text {
    margin-bottom: 40px;
    font-size: 12px;
    line-height: 2;
  }
  .detail .detail_more a.detail_more_tag {
    font-size: 10px;
    line-height: 1.8;
    display: inline-block;
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
  .detail .detail_data {
    width: 648px;
    margin: 0 auto 160px;
  }
  .detail .detail_data h4 {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 20px;
  }
  .detail .detail_data p {
    font-size: 11px;
    line-height: 1.8;
    margin-bottom: 40px;
  }
  .detail .detail_data p:last-child {
    margin-bottom: 0;
  }
  .detail .detail_data p.detail_data_link {
    margin-bottom: 20px;
  }
  .detail .detail_data p a {
    font-size: 10px;
    line-height: 1.8;
    display: inline-block;
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
  .detail .detail_data p a:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .detail .detail-nav {
    margin: 0 4.53125vw;
    display: flex;
    justify-content: space-between;
  }
  .detail .detail-nav div a {
    font-size: 11px;
  }
  .detail .detail-nav .detail-nav-prev {
    display: inline-block;
    width: 40px;
    height: 25px;
    position: relative;
  }
  .detail .detail-nav .detail-nav-prev:before {
    position: absolute;
    left: 0px;
    top: 10px;
    height: 1px;
    width: 40px;
    background: #000;
    content: "";
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 0% 0;
  }
  .detail .detail-nav .detail-nav-prev:after {
    position: absolute;
    left: 0px;
    top: 10px;
    height: 1px;
    width: 40px;
    background: #000;
    content: "";
    transform: rotate(-15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 0% 0;
  }
  .detail .detail-nav .detail-nav-next {
    display: inline-block;
    width: 40px;
    height: 25px;
    position: relative;
  }
  .detail .detail-nav .detail-nav-next:before {
    position: absolute;
    left: 0px;
    top: 10px;
    height: 1px;
    width: 40px;
    background: #000;
    content: "";
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 0;
  }
  .detail .detail-nav .detail-nav-next:after {
    position: absolute;
    left: 0px;
    top: 10px;
    height: 1px;
    width: 40px;
    background: #000;
    content: "";
    transform: rotate(-15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 0;
  }
}
@media screen and (max-width: 960px) {
  .detail {
    background: #F5F5F5;
    padding-top: 28vw;
  }
  .detail .detail_visual {
    height: 56.25vw;
    margin-bottom: 21.3333333333vw;
  }
  .detail .detail_title {
    width: auto;
    margin: 0 10.6666666667vw 10.6666666667vw;
  }
  .detail .detail_title h1 {
    font-size: 4vw;
    font-weight: bold;
    margin-bottom: 2.9333333333vw;
  }
  .detail .detail_title h2 {
    font-size: 4vw;
    line-height: 1.833333;
  }
  .detail .detail_text {
    width: auto;
    margin: 0 10.6666666667vw 10.6666666667vw;
  }
  .detail .detail_text h3 {
    font-size: 3.2vw;
    font-weight: bold;
    margin-bottom: 1vw;
    line-height: 1.833333;
  }
  .detail .detail_text p {
    font-size: 3.2vw;
    line-height: 2;
  }
  .detail iframe {
    width: 78.6666666667vw;
    height: 44.2666666667vw;
    margin: 0 10.6666666667vw 2.6666666667vw;
    display: block;
  }
  .detail .detail_image {
    width: auto;
    margin: 0 10.6666666667vw 10.6666666667vw;
  }
  .detail .detail_image img {
    margin-bottom: 2.6666666667vw;
    width: 100%;
  }
  .detail .detail_image p {
    color: #777777;
    font-size: 2.6666666667vw;
  }
  .detail .detail-list {
    padding: 0 0 0 5.3333333333vw;
    margin-bottom: 10.6666666667vw;
    overflow: hidden;
  }
  .detail .detail-list .slick-list {
    overflow: visible;
  }
  .detail .detail-list img {
    width: auto;
    height: 36vw;
    margin-left: 5.3333333333vw;
    margin-bottom: 2.6666666667vw;
  }
  .detail .detail-list p {
    margin-left: 5.3333333333vw;
    color: #777777;
    font-size: 2.6666666667vw;
  }
  .detail .detail-list .slick-prev {
    position: absolute;
    top: 0px;
    height: 36vw;
    width: 0%;
    z-index: 10;
    left: 0;
    opacity: 0;
  }
  .detail .detail-list .slick-next {
    position: absolute;
    top: 0px;
    right: 0;
    height: 36vw;
    width: 0%;
    z-index: 10;
    opacity: 0;
  }
  .detail .detail_more {
    width: auto;
    margin: 0 10.6666666667vw 17.3333333333vw;
  }
  .detail .detail_more p.detail_more_text {
    margin-bottom: 10.6666666667vw;
    font-size: 3.2vw;
    line-height: 2;
  }
  .detail .detail_more a.detail_more_tag {
    font-size: 2.6666666667vw;
    line-height: 1.8;
    display: inline-block;
    background: #fff;
    padding-left: 1.3333333333vw;
    padding-right: 1.3333333333vw;
  }
  .detail .detail_data {
    width: auto;
    margin: 0 10.6666666667vw 17.3333333333vw;
  }
  .detail .detail_data h4 {
    font-weight: bold;
    font-size: 2.6666666667vw;
    margin-bottom: 5.3333333333vw;
  }
  .detail .detail_data p {
    font-size: 2.6666666667vw;
    line-height: 1.8;
    margin-bottom: 10.6666666667vw;
  }
  .detail .detail_data p:last-child {
    margin-bottom: 0;
  }
  .detail .detail_data p.detail_data_link {
    margin-bottom: 5.3333333333vw;
  }
  .detail .detail_data p a {
    font-size: 2.6666666667vw;
    line-height: 1.8;
    display: inline-block;
    background: #fff;
    padding-left: 1.3333333333vw;
    padding-right: 1.3333333333vw;
  }
  .detail .detail-nav {
    width: auto;
    margin: 0 10.6666666667vw;
    display: flex;
    justify-content: space-between;
  }
  .detail .detail-nav div a {
    font-size: 2.9333333333vw;
  }
  .detail .detail-nav .detail-nav-prev {
    display: inline-block;
    width: 10.6666666667vw;
    height: 6.6666666667vw;
    position: relative;
  }
  .detail .detail-nav .detail-nav-prev:before {
    position: absolute;
    left: 0vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #000;
    content: "";
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 0% 0;
  }
  .detail .detail-nav .detail-nav-prev:after {
    position: absolute;
    left: 0vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #000;
    content: "";
    transform: rotate(-15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 0% 0;
  }
  .detail .detail-nav .detail-nav-next {
    display: inline-block;
    width: 10.6666666667vw;
    height: 6.6666666667vw;
    position: relative;
  }
  .detail .detail-nav .detail-nav-next:before {
    position: absolute;
    left: 0vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #000;
    content: "";
    transform: rotate(15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 0;
  }
  .detail .detail-nav .detail-nav-next:after {
    position: absolute;
    left: 0vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #000;
    content: "";
    transform: rotate(-15deg);
    transition: all 0.3s ease-in-out;
    transform-origin: 100% 0;
  }
}
@media screen and (min-width: 960px) {
  .error {
    height: 100vh;
  }
  .error a {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .error a img {
    margin-bottom: 78px;
    width: 595px;
  }
  .error a h1 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .error a p {
    font-size: 12px;
  }
}
@media screen and (max-width: 960px) {
  .error {
    height: 100vh;
  }
  .error a {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .error a img {
    margin-bottom: 17.1466666667vw;
    width: 66.4vw;
  }
  .error a h1 {
    font-size: 5.8666666667vw;
    margin-bottom: 5.3333333333vw;
    font-weight: bold;
    white-space: nowrap;
  }
  .error a p {
    font-size: 3.2vw;
  }
}
@media screen and (min-width: 960px) {
  .loader {
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
  }
  .loader img {
    width: 27.34375vw;
    position: absolute;
    top: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 960px) {
  .loader {
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
  }
  .loader img {
    width: 72vw;
    position: absolute;
    top: 50%;
    top: calc(var(--vh, 1vh) * 50);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 960px) {
  .other {
    background: #F5F5F5;
    padding-top: 20.78125vw;
  }
  .other h1 {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .other .other-h1-text p {
    text-align: center;
    line-height: 2;
    font-size: 15px;
    padding-bottom: 133px;
  }
  .other .other-list {
    width: 76.5625vw;
    margin: 0 auto;
  }
  .other .other-list ul li {
    position: relative;
    height: 22.109375vw;
    width: 100%;
    margin-bottom: 140px;
  }
  .other .other-list ul li img {
    position: absolute;
    right: 0;
    width: 37.65625vw;
    height: 22.109375vw;
  }
  .other .other-list ul li .other-list-name {
    font-size: 20px;
    margin-bottom: 20px;
    width: 31.25vw;
  }
  .other .other-list ul li .other-list-text {
    width: 31.25vw;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 2;
  }
  .other .other-list ul li .other-list-link {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    background-color: #fff;
    padding: 0 10px;
    display: inline-block;
  }
  .other .other-list ul li .other-list-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .other .other-list ul li p {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 960px) {
  .other {
    background: #F5F5F5;
    padding-top: 35vw;
  }
  .other h1 {
    font-size: 5.8666666667vw;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10vw;
  }
  .other .other-h1-text p {
    text-align: center;
    line-height: 2;
    font-size: 4vw;
    margin: 0 10.6666666667vw;
    padding-bottom: 15vw;
  }
  .other .other-list {
    width: auto;
    margin: 0 auto;
  }
  .other .other-list ul {
    margin: 0 10.6666666667vw;
  }
  .other .other-list ul li {
    position: relative;
    width: auto;
    margin-bottom: 21.3333333333vw;
  }
  .other .other-list ul li:last-child {
    margin-bottom: 0;
  }
  .other .other-list ul li img {
    margin-bottom: 10.6666666667vw;
  }
  .other .other-list ul li .other-list-name {
    font-size: 5.3333333333vw;
    margin-bottom: 5vw;
    width: auto;
  }
  .other .other-list ul li .other-list-text {
    font-size: 3.2vw;
    width: auto;
    line-height: 1.8333333;
    margin-bottom: 8vw;
  }
  .other .other-list ul li .other-list-link {
    font-size: 2.6666666667vw;
    line-height: 4vw;
    height: 4vw;
    background-color: #fff;
  }
  .other .other-list ul li p {
    margin-bottom: 5vw;
  }
}
.scrl {
  width: 80vw;
  margin: 0 auto;
}
.scrl div, .scrl p, .scrl h1 {
  margin-bottom: 120px;
}

@media screen and (min-width: 960px) {
  .top {
    height: 100vh;
    overflow: hidden;
    position: relative;
    /*
    .top_news {
      position: absolute;
      top: 17.5vh;
      left: 13.046875vw;
      font-size: 0.9375vw;
      color: #fff;
      z-index: 1;
      padding-bottom: 0.9375vw;
      border-bottom: 1px solid #fff;
      mix-blend-mode: difference;
      cursor: pointer;
    }
    .top_news a {
        color: #fff;
      }
    .top_news:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0px;
      background: #fff;
      transition: all ease-out 0.16s;
      display: block;
      mix-blend-mode: difference;
    }
    .top_news:hover:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2.8125vw;
      background: #fff;
      display: block;
      mix-blend-mode: difference;
    }
    */
  }
  .top h1 {
    position: absolute;
    bottom: 8.1484375vw;
    right: 11.3671875vw;
    width: 77.265625vw;
    z-index: 1;
    pointer-events: none;
  }
  .top h1 img {
    width: 100%;
  }
  .top .top_news {
    position: absolute;
    top: 10vh;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.05rem;
    line-height: 1.8;
    color: #fff;
    z-index: 1;
    width: 415px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    background: #000;
    text-align: right;
    cursor: pointer;
  }
  .top .top_news a {
    color: #fff;
    font-weight: 500;
  }
  .top .top_news:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background: #fff;
    transition: all ease-out 0.16s;
    display: block;
    mix-blend-mode: difference;
  }
  .top .top_news:hover:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: block;
    mix-blend-mode: difference;
  }
  .top .top_content {
    height: 0%;
    width: 100%;
    overflow: hidden;
    transition: linear 0.01s 1s all;
  }
  .top .top_content .gallery {
    transform: scale(0.7);
    opacity: 0;
    cursor: none;
    will-change: transform;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s all;
  }
  .top .top_content.-show {
    height: 100%;
  }
  .top .top_content.-show .gallery {
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s 1s all;
    transform: scale(1);
    opacity: 1;
  }
  .top .anim1 {
    width: 1041.6666666667vw;
    height: 36.4583333333vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: relative;
    top: calc(50% - 18.2291666666vw);
    will-change: transform;
  }
  .top .anim1 div {
    margin-right: 9.375vw;
    position: relative;
  }
  .top .anim1 .gallery .-w {
    width: 36.4583333333vw;
    height: auto;
  }
  .top .anim1 .gallery .-w.-s90 {
    width: 32.8125vw;
  }
  .top .anim1 .gallery .-w.-s80 {
    width: 29.1666666666vw;
  }
  .top .anim1 .gallery .-w.-s70 {
    width: 25.5208333333vw;
  }
  .top .anim1 .gallery .-h {
    width: auto;
    height: 36.4583333333vw;
  }
  .top .anim1 .gallery .-h.-s90 {
    height: 32.8125vw;
  }
  .top .anim1 .gallery .-h.-s80 {
    height: 29.1666666666vw;
  }
  .top .anim1 .gallery .-h.-s70 {
    height: 25.5208333333vw;
  }
  .top .anim1 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .anim2_1 {
    width: 10000px;
    height: 18.2291666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    bottom: calc(50% + 2.34375vw);
    will-change: transform;
  }
  .top .anim2_1 div {
    margin-right: 4.6875vw;
    position: relative;
  }
  .top .anim2_1 .gallery .-w {
    width: 18.2291666667vw;
    height: auto;
  }
  .top .anim2_1 .gallery .-w.-s90 {
    width: 16.40625vw;
  }
  .top .anim2_1 .gallery .-w.-s80 {
    width: 14.5833333334vw;
  }
  .top .anim2_1 .gallery .-w.-s70 {
    width: 12.7604166667vw;
  }
  .top .anim2_1 .gallery .-h {
    width: auto;
    height: 18.2291666667vw;
  }
  .top .anim2_1 .gallery .-h.-s90 {
    height: 16.40625vw;
  }
  .top .anim2_1 .gallery .-h.-s80 {
    height: 14.5833333334vw;
  }
  .top .anim2_1 .gallery .-h.-s70 {
    height: 12.7604166667vw;
  }
  .top .anim2_1 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .anim2_2 {
    width: 10000px;
    height: 18.2291666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    top: calc(50% + 2.34375vw);
    will-change: transform;
  }
  .top .anim2_2 div {
    margin-right: 4.6875vw;
    position: relative;
  }
  .top .anim2_2 .gallery .-w {
    width: 18.2291666667vw;
    height: auto;
  }
  .top .anim2_2 .gallery .-w.-s90 {
    width: 16.40625vw;
  }
  .top .anim2_2 .gallery .-w.-s80 {
    width: 14.5833333334vw;
  }
  .top .anim2_2 .gallery .-w.-s70 {
    width: 12.7604166667vw;
  }
  .top .anim2_2 .gallery .-h {
    width: auto;
    height: 18.2291666667vw;
  }
  .top .anim2_2 .gallery .-h.-s90 {
    height: 16.40625vw;
  }
  .top .anim2_2 .gallery .-h.-s80 {
    height: 14.5833333334vw;
  }
  .top .anim2_2 .gallery .-h.-s70 {
    height: 12.7604166667vw;
  }
  .top .anim2_2 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .rotate {
    transform: rotate(30deg);
    width: 100%;
    height: 100%;
  }
  .top .anim3 {
    height: 520.8333333333vw;
    width: 41.6666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    position: relative;
    left: calc(50% - 20.888888888vw);
    will-change: transform;
  }
  .top .anim3 div {
    margin-bottom: 10.7291666667vw;
    position: relative;
  }
  .top .anim3 .gallery .-w {
    width: 41.6666666667vw;
    height: auto;
  }
  .top .anim3 .gallery .-w.-s90 {
    width: 37.5vw;
  }
  .top .anim3 .gallery .-w.-s80 {
    width: 33.3333333334vw;
  }
  .top .anim3 .gallery .-w.-s70 {
    width: 29.1666666667vw;
  }
  .top .anim3 .gallery .-h {
    width: auto;
    height: 41.6666666667vw;
  }
  .top .anim3 .gallery .-h.-s90 {
    height: 37.5vw;
  }
  .top .anim3 .gallery .-h.-s80 {
    height: 33.3333333334vw;
  }
  .top .anim3 .gallery .-h.-s70 {
    height: 29.1666666667vw;
  }
  .top .anim3 .gallery p {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .top .anim4_1 {
    height: 10000px;
    width: 29.1666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    text-align: center;
    position: absolute;
    right: calc(50% + 70px);
    will-change: transform;
  }
  .top .anim4_1 div {
    margin-bottom: 7.2916666667vw;
    position: relative;
  }
  .top .anim4_1 .gallery .-w {
    width: 29.1666666667vw;
    height: auto;
  }
  .top .anim4_1 .gallery .-w.-s90 {
    width: 26.25vw;
  }
  .top .anim4_1 .gallery .-w.-s80 {
    width: 23.3333333334vw;
  }
  .top .anim4_1 .gallery .-w.-s70 {
    width: 20.4166666667vw;
  }
  .top .anim4_1 .gallery .-h {
    width: auto;
    height: 29.1666666667vw;
  }
  .top .anim4_1 .gallery .-h.-s90 {
    height: 26.25vw;
  }
  .top .anim4_1 .gallery .-h.-s80 {
    height: 23.3333333334vw;
  }
  .top .anim4_1 .gallery .-h.-s70 {
    height: 20.4166666667vw;
  }
  .top .anim4_1 .gallery p {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .top .anim4_2 {
    height: 10000px;
    width: 560px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    position: absolute;
    left: calc(50% + 70px);
    text-align: center;
    will-change: transform;
  }
  .top .anim4_2 div {
    margin-bottom: 7.2916666667vw;
    position: relative;
  }
  .top .anim4_2 .gallery .-w {
    width: 29.1666666667vw;
    height: auto;
  }
  .top .anim4_2 .gallery .-w.-s90 {
    width: 26.25vw;
  }
  .top .anim4_2 .gallery .-w.-s80 {
    width: 23.3333333334vw;
  }
  .top .anim4_2 .gallery .-w.-s70 {
    width: 20.4166666667vw;
  }
  .top .anim4_2 .gallery .-h {
    width: auto;
    height: 29.1666666667vw;
  }
  .top .anim4_2 .gallery .-h.-s90 {
    height: 26.25vw;
  }
  .top .anim4_2 .gallery .-h.-s80 {
    height: 23.3333333334vw;
  }
  .top .anim4_2 .gallery .-h.-s70 {
    height: 20.4166666667vw;
  }
  .top .anim4_2 .gallery p {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .top_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;
  }
  .top_layer .top_layer_image {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 73.75vh;
  }
  .top_layer .top_layer_image img {
    height: 73.75vh;
    width: auto;
    max-width: unset;
  }
  .top_layer .top_layer_image p {
    font-size: 0.78125vw;
    margin-top: 0.78125vw;
    color: #fff;
    font-weight: bold;
  }
  .top_layer .top_layer_image .top_layer_close {
    width: 40px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }
  .top_layer .top_layer_image .top_layer_close:before {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #fff;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .top_layer .top_layer_image .top_layer_close:after {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #fff;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
  .top_layer .top_layer_image .top_layer_close:hover:before {
    transform: rotate(-25deg);
  }
  .top_layer .top_layer_image .top_layer_close:hover:after {
    transform: rotate(25deg);
  }
  @keyframes scroll {
    0% {
      transform: translate(0px, 0);
    }
    100% {
      transform: translate(-2000px, 0);
    }
  }
  @keyframes scrollv {
    0% {
      transform: translate(0px, 0);
    }
    100% {
      transform: translate(0px, -2000px);
    }
  }
}
@media screen and (max-width: 960px) {
  .top {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
    /*
      .top_news {
      position: absolute;
      top: 5.223880597vh;
      left: 8vw;
      font-size: 3.2vw;
      letter-spacing: 0.05rem;
      color: #fff;
      z-index: 1;
      padding-bottom: 3.2vw;
      border-bottom: 1px solid #fff;
      mix-blend-mode: difference;
      cursor: pointer;
    }
    .top_news a {
      color: #fff;
    }
    .top_news:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0px;
      background: #fff;
      transition: all ease-out 0.16s;
      display: block;
      mix-blend-mode: difference;
    }*/
  }
  .top h1 {
    position: absolute;
    top: 50vh;
    top: calc(var(--vh, 1vh) * 50);
    transform: translate(0, -50%);
    left: 2.6666666667vw;
    width: 94.6666666667vw;
    z-index: 1;
    pointer-events: none;
  }
  .top h1 img {
    width: 100%;
  }
  .top .top_news {
    position: absolute;
    top: 6.1333333333vw;
    left: 0vw;
    font-size: 3.2vw;
    font-weight: 500;
    letter-spacing: 0.05rem;
    color: #fff;
    z-index: 1;
    line-height: 1.8;
    padding-top: 4.2666666667vw;
    padding-bottom: 4.2666666667vw;
    color: #fff;
    background: #01A0E9;
    background: #000;
    width: 66.1333333333vw;
    text-align: center;
    cursor: pointer;
  }
  .top .top_news a {
    color: #fff;
  }
  .top .top_news:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background: #fff;
    transition: all ease-out 0.16s;
    display: block;
    mix-blend-mode: difference;
  }
  .top .top_content {
    height: 0%;
    width: 100%;
    overflow: hidden;
    transition: linear 0.01s 1s all;
    position: relative;
    left: -99999px;
  }
  .top .top_content .gallery {
    transform: scale(0.7);
    opacity: 0;
    cursor: none;
    will-change: transform;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s all;
  }
  .top .top_content.-show {
    height: 100%;
    left: 0;
  }
  .top .top_content.-show .gallery {
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s 1s all;
    transform: scale(1);
    opacity: 1;
  }
  .top .anim1 {
    width: 5333.3333333333vw;
    height: 89.6vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: relative;
    top: calc(50vh - 44.8vw);
    top: calc(var(--vh, 1vh) * 50 - 44.8vw);
    will-change: transform;
  }
  .top .anim1 div {
    margin-right: 25.8666666667vw;
    position: relative;
  }
  .top .anim1 .gallery .-w {
    width: 89.6vw;
    height: auto;
  }
  .top .anim1 .gallery .-w.-s90 {
    width: 80.64vw;
  }
  .top .anim1 .gallery .-w.-s80 {
    width: 71.68vw;
  }
  .top .anim1 .gallery .-w.-s70 {
    width: 62.72vw;
  }
  .top .anim1 .gallery .-h {
    width: auto;
    height: 89.6vw;
  }
  .top .anim1 .gallery .-h.-s90 {
    height: 80.64vw;
  }
  .top .anim1 .gallery .-h.-s80 {
    height: 71.68vw;
  }
  .top .anim1 .gallery .-h.-s70 {
    height: 62.72vw;
  }
  .top .anim1 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .anim2_1 {
    width: 10000px;
    height: 52.2666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    bottom: calc(50vh + 6.6666666667vw);
    bottom: calc(var(--vh, 1vh) * 50 + 6.6666666667vw);
    will-change: transform;
  }
  .top .anim2_1 div {
    margin-right: 13.3333333333vw;
    position: relative;
  }
  .top .anim2_1 .gallery .-w {
    width: 52.2666666667vw;
    height: auto;
  }
  .top .anim2_1 .gallery .-w.-s90 {
    width: 47.04vw;
  }
  .top .anim2_1 .gallery .-w.-s80 {
    width: 41.8133333334vw;
  }
  .top .anim2_1 .gallery .-w.-s70 {
    width: 36.5866666667vw;
  }
  .top .anim2_1 .gallery .-h {
    width: auto;
    height: 52.2666666667vw;
  }
  .top .anim2_1 .gallery .-h.-s90 {
    height: 47.04vw;
  }
  .top .anim2_1 .gallery .-h.-s80 {
    height: 41.8133333334vw;
  }
  .top .anim2_1 .gallery .-h.-s70 {
    height: 36.5866666667vw;
  }
  .top .anim2_1 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .anim2_2 {
    width: 10000px;
    height: 52.2666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    position: absolute;
    top: calc(50vh + 6.6666666667vw);
    top: calc(var(--vh, 1vh) * 50 + 6.6666666667vw);
    will-change: transform;
  }
  .top .anim2_2 div {
    margin-right: 13.3333333333vw;
    position: relative;
  }
  .top .anim2_2 .gallery .-w {
    width: 52.2666666667vw;
    height: auto;
  }
  .top .anim2_2 .gallery .-w.-s90 {
    width: 47.04vw;
  }
  .top .anim2_2 .gallery .-w.-s80 {
    width: 41.8133333334vw;
  }
  .top .anim2_2 .gallery .-w.-s70 {
    width: 36.5866666667vw;
  }
  .top .anim2_2 .gallery .-h {
    width: auto;
    height: 52.2666666667vw;
  }
  .top .anim2_2 .gallery .-h.-s90 {
    height: 47.04vw;
  }
  .top .anim2_2 .gallery .-h.-s80 {
    height: 41.8133333334vw;
  }
  .top .anim2_2 .gallery .-h.-s70 {
    height: 36.5866666667vw;
  }
  .top .anim2_2 .gallery p {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .top .rotate {
    transform: rotate(30deg);
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .top .anim3 {
    height: 2666.6666666667vw;
    width: 89.6vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    position: relative;
    left: calc(50% - 44.8vw);
    will-change: transform;
  }
  .top .anim3 div {
    margin-bottom: 23.2vw;
    position: relative;
  }
  .top .anim3 .gallery .-w {
    width: 89.6vw;
    height: auto;
  }
  .top .anim3 .gallery .-w.-s90 {
    width: 80.64vw;
  }
  .top .anim3 .gallery .-w.-s80 {
    width: 71.68vw;
  }
  .top .anim3 .gallery .-w.-s70 {
    width: 62.72vw;
  }
  .top .anim3 .gallery .-h {
    width: auto;
    height: 89.6vw;
  }
  .top .anim3 .gallery .-h.-s90 {
    height: 80.64vw;
  }
  .top .anim3 .gallery .-h.-s80 {
    height: 71.68vw;
  }
  .top .anim3 .gallery .-h.-s70 {
    height: 62.72vw;
  }
  .top .anim3 .gallery p {
    display: block;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .top .anim4_1 {
    height: 10000px;
    width: 64.2666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    position: absolute;
    right: calc(50% + 11.4666666667vw);
    will-change: transform;
  }
  .top .anim4_1 div {
    margin-bottom: 16.5333333333vw;
    position: relative;
  }
  .top .anim4_1 .gallery .-w {
    width: 64.2666666667vw;
    height: auto;
  }
  .top .anim4_1 .gallery .-w.-s90 {
    width: 57.84vw;
  }
  .top .anim4_1 .gallery .-w.-s80 {
    width: 51.4133333334vw;
  }
  .top .anim4_1 .gallery .-w.-s70 {
    width: 44.9866666667vw;
  }
  .top .anim4_1 .gallery .-h {
    width: auto;
    height: 64.2666666667vw;
  }
  .top .anim4_1 .gallery .-h.-s90 {
    height: 57.84vw;
  }
  .top .anim4_1 .gallery .-h.-s80 {
    height: 51.4133333334vw;
  }
  .top .anim4_1 .gallery .-h.-s70 {
    height: 44.9866666667vw;
  }
  .top .anim4_1 .gallery p {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }
  .top .anim4_2 {
    height: 10000px;
    width: 64.2666666667vw;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    flex-flow: column;
    position: absolute;
    left: calc(50% + 11.4666666667vw);
    will-change: transform;
  }
  .top .anim4_2 div {
    margin-bottom: 16.5333333333vw;
    position: relative;
  }
  .top .anim4_2 .gallery .-w {
    width: 64.2666666667vw;
    height: auto;
  }
  .top .anim4_2 .gallery .-w.-s90 {
    width: 57.84vw;
  }
  .top .anim4_2 .gallery .-w.-s80 {
    width: 51.4133333334vw;
  }
  .top .anim4_2 .gallery .-w.-s70 {
    width: 44.9866666667vw;
  }
  .top .anim4_2 .gallery .-h {
    width: auto;
    height: 64.2666666667vw;
  }
  .top .anim4_2 .gallery .-h.-s90 {
    height: 57.84vw;
  }
  .top .anim4_2 .gallery .-h.-s80 {
    height: 51.4133333334vw;
  }
  .top .anim4_2 .gallery .-h.-s70 {
    height: 44.9866666667vw;
  }
  .top .anim4_2 .gallery p {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }
  .top_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;
  }
  .top_layer .top_layer_image {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 78.6666666667vw;
  }
  .top_layer .top_layer_image img {
    height: auto;
    width: 78.6666666667vw;
    max-width: unset;
  }
  .top_layer .top_layer_image p {
    font-size: 2.6666666667vw;
    margin-top: 2.6666666667vw;
    color: #fff;
    font-weight: bold;
  }
  .top_layer .top_layer_image .top_layer_close {
    width: 10.6666666667vw;
    height: 5.3333333333vw;
    display: inline-block;
    position: absolute;
    top: -8vw;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }
  .top_layer .top_layer_image .top_layer_close:before {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #fff;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .top_layer .top_layer_image .top_layer_close:after {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #fff;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
  @keyframes scroll {
    0% {
      transform: translate(0px, 0);
    }
    100% {
      transform: translate(-2000px, 0);
    }
  }
  @keyframes scrollv {
    0% {
      transform: translate(0px, 0);
    }
    100% {
      transform: translate(0px, -2000px);
    }
  }
}
@media screen and (min-width: 960px) {
  .works {
    padding-top: 10.78125vw;
  }
  .works nav {
    display: flex;
    justify-content: space-between;
    margin-left: 4.53125vw;
    margin-right: 4.53125vw;
    margin-bottom: 71px;
  }
  .works nav h2 {
    display: none;
    font-family: nimbus-sans, sans-serif;
    font-size: 12px;
  }
  .works nav h2 p {
    margin-left: 20px;
    margin-right: 40px;
    position: relative;
    top: 3px;
    display: inline-block;
    font-size: 20px;
    font-family: YakuHanJP, YuGothic, "Yu Gothic Medium", "Yu Gothic", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, Verdana, Meiryo, sans-serif;
  }
  .works nav h2 a {
    width: 20px;
    height: 10px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .works nav h2 a:before {
    position: absolute;
    left: 0vw;
    top: 5px;
    height: 1px;
    width: 20px;
    background: #111;
    content: "";
    transition: all 0.3s ease-in-out;
    transform: rotate(25deg);
  }
  .works nav h2 a:after {
    position: absolute;
    left: 0vw;
    top: 5px;
    height: 1px;
    width: 20px;
    background: #111;
    content: "";
    transition: all 0.3s ease-in-out;
    transform: rotate(-25deg);
  }
  .works nav h2 a:hover:before {
    transform: rotate(-25deg);
  }
  .works nav h2 a:hover:after {
    transform: rotate(25deg);
  }
  .works nav .works-category,
  .works nav .works-type {
    display: flex;
    font-size: 12px;
  }
  .works nav .works-category li,
  .works nav .works-type li {
    margin-right: 50px;
  }
  .works nav .works-category li a,
  .works nav .works-type li a {
    padding-bottom: 5px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    line-height: 20px;
    background: transparent;
    cursor: pointer;
  }
  .works nav .works-category li a::after,
  .works nav .works-type li a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: #000;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.3s;
  }
  .works nav .works-category li a.-current::after,
  .works nav .works-type li a.-current::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .works nav .works-category li a:hover::after,
  .works nav .works-type li a:hover::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .works nav .works-category li input,
  .works nav .works-type li input {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    width: 184px;
    font-size: 12px;
    padding-bottom: 8px;
  }
  .works nav .works-category li input::placeholder,
  .works nav .works-type li input::placeholder {
    color: #000;
  }
  .works nav .works-category li.works-search,
  .works nav .works-type li.works-search {
    position: relative;
    top: 2px;
  }
  .works nav .works-category li.works-search img,
  .works nav .works-type li.works-search img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -66%);
    width: 32px;
    padding: 10px;
    cursor: pointer;
  }
  .works nav .works-category li.works-search img:hover,
  .works nav .works-type li.works-search img:hover {
    opacity: 0.4;
  }
  .works nav .works-type {
    display: none;
  }
  .works nav .works-btn {
    cursor: pointer;
    text-align: right;
  }
  .works nav .works-btn h3 {
    position: relative;
    line-height: 25.5px;
    font-size: 12px;
  }
  .works nav .works-btn h3 img {
    width: 25px;
    height: 10px;
    margin-top: 8px;
    margin-right: 10px;
    transition: all 0.4s ease-in-out;
  }
  .works nav .works-btn h3:hover img {
    transform: rotate(-180deg);
  }
  .works .works-slide {
    padding: 0 0 0 4.53125vw;
    margin-bottom: 22px;
  }
  .works .works-slide .slick-list {
    overflow: visible;
  }
  .works .works-slide .works-slide-element {
    padding-right: 80px;
    height: 419px;
  }
  .works .works-slide .works-slide-element img {
    width: 518px;
    height: 303px;
  }
  .works .works-slide .works-slide-element .hov-image {
    margin-bottom: 19px;
    display: inline-block;
  }
  .works .works-slide .works-slide-element .works-slide-name {
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 7px;
  }
  .works .works-slide .works-slide-element .works-slide-kana {
    font-size: 13px;
    line-height: 1.5;
  }
  .works .works-slide .slick-prev {
    position: absolute;
    top: 0px;
    height: 303px;
    z-index: 10;
    left: 0;
    width: 10%;
    opacity: 0;
  }
  .works .works-slide .slick-next {
    position: absolute;
    top: 0px;
    right: 0;
    height: 303px;
    z-index: 10;
    width: 10%;
    opacity: 0;
  }
  .works .works-list {
    margin: 0 4.53125vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .works .works-list span {
    content: "";
    display: inline-block;
    width: 46.15%;
  }
  .works .works-list .works-list-element {
    width: 46.15%;
    margin-bottom: 100px;
  }
  .works .works-list .works-list-element img {
    width: 100%;
  }
  .works .works-list .works-list-element .hov-image {
    margin-bottom: 20px;
  }
  .works .works-list .works-list-element .works-list-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px;
    line-height: 1.5;
  }
  .works .works-list .works-list-element .works-list-kana {
    font-size: 13px;
    line-height: 1.5;
  }
  .works .presen-list {
    margin-left: 6.796875vw;
    margin-right: 6.796875vw;
  }
  .works .presen-list li {
    margin-bottom: 6.25vw;
  }
  .works .presen-list li img {
    width: 100%;
  }
  .works .presen-list li iframe {
    width: 86.40625vw;
    height: 48.59375vw;
    margin-bottom: 20px;
  }
  .works .presen-list li p a {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    background-color: #fff;
    padding: 0 10px;
    display: inline-block;
  }
  .works .presen-list li p a:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .works .presen-list li:last-child {
    margin-bottom: 0;
  }
  .works .works-list span {
    width: 30%;
  }
  .works .works-list .works-list-element {
    width: 30%;
  }
  .works .works-slide .works-slide-element {
    height: calc(23.671875vw + 116px);
  }
  .works .works-slide .works-slide-element img {
    width: 40.46875vw;
    height: 23.671875vw;
  }
}
@media screen and (min-width: 1280px) {
  .works .works-list span {
    width: 30%;
  }
  .works .works-list .works-list-element {
    width: 30%;
  }
  .works .works-slide .works-slide-element {
    height: calc(23.671875vw + 116px);
  }
  .works .works-slide .works-slide-element img {
    width: 40.46875vw;
    height: 23.671875vw;
  }
}
@media screen and (min-width: 1900px) {
  .works .works-list span {
    width: 22.2222%;
  }
  .works .works-list .works-list-element {
    width: 22.2222%;
  }
}
@media screen and (max-width: 960px) {
  .works {
    padding-top: 30vw;
  }
  .works nav {
    margin-bottom: 10vw;
  }
  .works nav h2 {
    display: none;
    padding-left: 10.6666666667vw;
    font-family: nimbus-sans, sans-serif;
    font-size: 3.2vw;
  }
  .works nav h2 p {
    margin-left: 5.3333333333vw;
    margin-right: 10.6666666667vw;
    position: relative;
    top: 0.8vw;
    display: inline-block;
    font-size: 5.3333333333vw;
    font-family: YakuHanJP, YuGothic, "Yu Gothic Medium", "Yu Gothic", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, Verdana, Meiryo, sans-serif;
  }
  .works nav h2 a {
    width: 5.3333333333vw;
    height: 2.6666666667vw;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  .works nav h2 a:before {
    position: absolute;
    left: 0vw;
    top: 1.3333333333vw;
    height: 1px;
    width: 5.3333333333vw;
    background: #111;
    content: "";
    transition: all 0.3s ease-in-out;
    transform: rotate(25deg);
  }
  .works nav h2 a:after {
    position: absolute;
    left: 0vw;
    top: 1.3333333333vw;
    height: 1px;
    width: 5.3333333333vw;
    background: #111;
    content: "";
    transition: all 0.3s ease-in-out;
    transform: rotate(-25deg);
  }
  .works nav .works-category,
  .works nav .works-type {
    display: flex;
    padding-left: 10.6666666667vw;
    width: 100%;
    overflow: auto;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
    margin-bottom: 7vw;
  }
  .works nav .works-category::-webkit-scrollbar,
  .works nav .works-type::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
  .works nav .works-category li,
  .works nav .works-type li {
    margin-right: 10.6666666667vw;
  }
  .works nav .works-category li:last-child,
  .works nav .works-type li:last-child {
    padding-right: 10.6666666667vw;
  }
  .works nav .works-category li a,
  .works nav .works-type li a {
    padding-bottom: 2.6666666667vw;
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #000;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
    line-height: 6.8vw;
    background: transparent;
    cursor: pointer;
  }
  .works nav .works-category li a::after,
  .works nav .works-type li a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: #000;
    transform: scale(0, 1);
    transform-origin: right top;
  }
  .works nav .works-category li a.-current::after,
  .works nav .works-type li a.-current::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .works nav .works-type {
    display: none;
  }
  .works nav .works-btn {
    cursor: pointer;
    margin-left: 10.6666666667vw;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
    text-align: left;
  }
  .works nav .works-btn h3 {
    position: relative;
    line-height: 6.8vw;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
  }
  .works nav .works-btn h3 img {
    width: 6.6666666667vw;
    height: 2.6666666667vw;
    margin-top: 2.1333333333vw;
    margin-right: 2.6666666667vw;
    transition: all 0.4s ease-in-out;
  }
  .works nav .works-btn ul {
    display: none;
  }
  .works nav input {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    width: 38.9333333333vw;
    font-size: 3.5vw;
    padding-bottom: 8px;
  }
  .works nav input::placeholder {
    color: #000;
  }
  .works nav .works-search {
    margin-left: 10.6666666667vw;
    margin-bottom: 16vw;
    position: relative;
  }
  .works nav .works-search img {
    position: absolute;
    left: 34.6666666667vw;
    top: 50%;
    padding-top: 2.6666666667vw;
    transform: translate(0, -100%);
    width: 3.2vw;
  }
  .works nav .works-search.-hide {
    display: none;
  }
  .works .works-slide {
    width: 100%;
    overflow: hidden;
    margin-bottom: 10vw;
  }
  .works .works-slide .works-slide-element {
    width: 92vw;
  }
  .works .works-slide .works-slide-element img {
    width: 89.3333333333vw;
    height: 52.2666666667vw;
  }
  .works .works-slide .works-slide-element .hov-image {
    margin-bottom: 2.6666666667vw;
    display: inline-block;
  }
  .works .works-slide .works-slide-element .works-slide-name {
    font-size: 3.2vw;
    line-height: 1.5;
    font-weight: bold;
    padding-left: 5.3333333333vw;
    margin-bottom: 1vw;
  }
  .works .works-slide .works-slide-element .works-slide-kana {
    padding-left: 5.3333333333vw;
    font-size: 3.2vw;
    line-height: 1.5;
  }
  .works .works-slide .slick-prev {
    position: absolute;
    top: 0px;
    height: 80.8vw;
    z-index: 10;
    left: 0;
    width: 0%;
    opacity: 0;
  }
  .works .works-slide .slick-next {
    position: absolute;
    top: 0px;
    right: 0;
    height: 80.8vw;
    z-index: 10;
    width: 0%;
    opacity: 0;
  }
  .works .works-list {
    width: auto;
    margin: 0 10.6666666667vw;
  }
  .works .works-list .works-list-element {
    width: auto;
    height: 70.6666666667vw;
    margin-bottom: 0;
  }
  .works .works-list .works-list-element img {
    width: 78.6666666667vw;
    margin-bottom: 4vw;
  }
  .works .works-list .works-list-element .works-list-name {
    font-size: 3.2vw;
    font-weight: bold;
    line-height: 1.5;
  }
  .works .works-list .works-list-element .works-list-kana {
    font-size: 3.2vw;
    line-height: 1.5;
  }
  .works .presen-list {
    margin-left: 10.6666666667vw;
    margin-right: 10.6666666667vw;
  }
  .works .presen-list li {
    margin-bottom: 10.6666666667vw;
  }
  .works .presen-list li a img {
    width: 100%;
  }
  .works .presen-list li p a {
    font-size: 2.6666666667vw;
    line-height: 4vw;
    height: 4vw;
    background-color: #fff;
  }
  .works .presen-list li iframe {
    width: 78.6666666667vw;
    height: 44.2666666667vw;
    margin-bottom: 5vw;
  }
  .works .presen-list li:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 751px) {
  .base {
    height: 0.78125vw;
    transform: rotate(45deg);
  }
  .base::after {
    content: "";
    clear: both;
    display: block;
  }
}
/* ==========================================================================
 *
 * Blocks - .footer
 *
========================================================================== */
.topPage .footer_pagetop_fixed {
  display: none;
}

@media screen and (min-width: 960px) {
  .topPage footer {
    display: none;
  }
  footer {
    background: #F5F5F5;
    padding-top: 200px;
    padding-bottom: 115px;
    text-align: center;
    position: relative;
  }
  footer h1 {
    mix-blend-mode: difference;
    margin-bottom: 80px;
  }
  footer h1 img {
    width: 171px;
    height: 25.5px;
  }
  footer ul {
    display: flex;
    justify-content: space-between;
    width: 128px;
    margin: 0 auto;
    margin-bottom: 80px;
  }
  footer ul li:nth-child(1) img {
    width: 23.5px;
    height: 19px;
  }
  footer ul li:nth-child(2) img {
    width: 16.3px;
    height: 18.3px;
  }
  footer ul li:nth-child(3) img {
    width: 20px;
    height: 20px;
  }
  footer p {
    font-size: 11px;
  }
  footer .footer_pagetop {
    display: none;
  }
}
.footer_pagetop_fixed {
  position: fixed;
  bottom: 60px;
  right: 4.84375vw;
  width: 40px;
  height: 20px;
  cursor: pointer;
}
.footer_pagetop_fixed span {
  display: block;
  position: absolute;
}
.footer_pagetop_fixed span:nth-child(1) {
  top: 0;
  left: 19.5px;
  width: 1px;
  height: 20px;
  background: #000;
  transition: all ease-in-out 0.3s;
}
.footer_pagetop_fixed span:nth-child(2) {
  top: 0;
  left: 19.5px;
  width: 1px;
  height: 20px;
  background: #000;
  transform-origin: 0.5px 0;
  transform: rotate(60deg);
  transition: all ease-in-out 0.3s;
}
.footer_pagetop_fixed span:nth-child(3) {
  top: 0;
  left: 19.5px;
  width: 1px;
  height: 20px;
  background: #000;
  transform-origin: 0.5px 0;
  transform: rotate(-60deg);
  transition: all ease-in-out 0.3s;
}
.footer_pagetop_fixed:hover span:nth-child(1) {
  top: -10px;
  height: 30px;
}
.footer_pagetop_fixed:hover span:nth-child(2) {
  top: -10px;
  transform: rotate(30deg);
}
.footer_pagetop_fixed:hover span:nth-child(3) {
  top: -10px;
  transform: rotate(-30deg);
}

@media screen and (max-width: 960px) {
  .footer_pagetop_fixed {
    display: none;
  }
  .topPage footer {
    display: none;
  }
  footer {
    background: #F5F5F5;
    padding-top: 38.4vw;
    padding-bottom: 30.6666666667vw;
    text-align: center;
    position: relative;
  }
  footer h1 {
    mix-blend-mode: difference;
    margin-bottom: 21.3333333333vw;
  }
  footer h1 img {
    width: 45.6vw;
    height: 6.8vw;
  }
  footer ul {
    display: flex;
    justify-content: space-between;
    width: 34.1333333333vw;
    margin: 0 auto;
    margin-bottom: 21.3333333333vw;
  }
  footer ul li:nth-child(1) img {
    width: 6.2666666667vw;
    height: 5.0666666667vw;
  }
  footer ul li:nth-child(2) img {
    width: 4.3466666667vw;
    height: 4.88vw;
  }
  footer ul li:nth-child(3) img {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
  }
  footer p {
    font-size: 2.9333333333vw;
  }
  footer .footer_pagetop {
    position: absolute;
    top: 17.3333333333vw;
    right: 8vw;
    width: 10.6666666667vw;
    height: 5.3333333333vw;
    cursor: pointer;
  }
  footer .footer_pagetop span {
    display: block;
    position: absolute;
  }
  footer .footer_pagetop span:nth-child(1) {
    top: 0;
    left: 5.2vw;
    width: 1px;
    height: 5.3333333333vw;
    background: #000;
    transition: all ease-in-out 0.3s;
  }
  footer .footer_pagetop span:nth-child(2) {
    top: 0;
    left: 5.2vw;
    width: 1px;
    height: 5.3333333333vw;
    background: #000;
    transform-origin: 0.5px 0;
    transform: rotate(60deg);
    transition: all ease-in-out 0.3s;
  }
  footer .footer_pagetop span:nth-child(3) {
    top: 0;
    left: 5.2vw;
    width: 1px;
    height: 5.3333333333vw;
    background: #000;
    transform-origin: 0.5px 0;
    transform: rotate(-60deg);
    transition: all ease-in-out 0.3s;
  }
}
/* ==========================================================================
 *
 * Blocks - .header
 *
========================================================================== */
@media screen and (min-width: 960px) {
  .worksPage .header ul.header_menu li:nth-child(1) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .detailPage .header ul.header_menu li:nth-child(1) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .presenPage .header ul.header_menu li:nth-child(1) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .aboutPage .header ul.header_menu li:nth-child(2) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .careersPage .header ul.header_menu li:nth-child(3) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .otherPage .header ul.header_menu li:nth-child(4) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .contactPage .header ul.header_menu li:nth-child(5) a::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .topPage .header h1 img {
    width: 0;
    overflow: hidden;
  }
  .header {
    width: auto;
    padding: 3.125vw 4.53125vw 0;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    mix-blend-mode: difference;
    background: transparent;
  }
  .header h1 img {
    width: 171px;
    height: 25.5px;
  }
  .header.-min ul.header_menu {
    transform: translate(0, -10vh);
  }
  .header.-min .header_btn {
    transform: translate(0, 0vw);
  }
  .header ul.header_menu {
    position: relative;
    transition: all 0.6s ease-in-out;
    display: flex;
  }
  .header ul.header_menu li {
    display: block;
    margin-left: 40px;
  }
  .header ul.header_menu li:last-child {
    margin-left: 60px;
  }
  .header ul.header_menu li a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.05rem;
    line-height: 25.5px;
    background: transparent;
    cursor: pointer;
  }
  .header ul.header_menu li a::after {
    position: absolute;
    bottom: -4px;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.4s;
  }
  .header ul.header_menu li a:hover::after {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  .header .header_btn {
    transform: translate(0, -5vw);
    transition: all 0.6s ease-in-out;
    width: 40px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 3.125vw;
    right: 4.6875vw;
    cursor: pointer;
  }
  .header .header_btn:before {
    position: absolute;
    left: 0vw;
    top: 5px;
    height: 1px;
    width: 40px;
    background: #fff;
    content: "";
    transition: all 0.3s ease-in-out;
  }
  .header .header_btn:after {
    position: absolute;
    left: 0vw;
    top: 15px;
    height: 1px;
    width: 40px;
    background: #fff;
    content: "";
    transition: all 0.3s ease-in-out;
  }
  .header .header_btn:hover:before {
    top: 15px;
  }
  .header .header_btn:hover:after {
    top: 5px;
  }
  .header_menuset {
    width: calc(4.6875vw + 235px);
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10001;
    text-align: right;
    padding-top: 3.125vw;
    padding-right: 4.6875vw;
    transition: all 0.6s ease-in-out;
    transform: translate(calc(4.6875vw + 235px), 0);
  }
  .header_menuset.-show {
    transform: translate(0, 0);
  }
  .header_menuset .header_menuset_close {
    width: 40px;
    height: 20px;
    display: inline-block;
    position: relative;
    margin-bottom: 65px;
    cursor: pointer;
  }
  .header_menuset .header_menuset_close:before {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #000;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .header_menuset .header_menuset_close:after {
    position: absolute;
    left: -3px;
    top: 10px;
    height: 1px;
    width: 46px;
    background: #000;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
  .header_menuset .header_menuset_close:hover:before {
    transform: rotate(-25deg);
  }
  .header_menuset .header_menuset_close:hover:after {
    transform: rotate(25deg);
  }
  .header_menuset ul.header_menuset_menu {
    margin-bottom: 80px;
  }
  .header_menuset ul.header_menuset_menu li {
    margin-bottom: 40px;
    font-size: 12px;
  }
  .header_menuset ul.header_menuset_menu li:last-child {
    margin-bottom: 0;
  }
  .header_menuset p {
    margin-bottom: 80px;
    font-size: 12px;
  }
  .header_menuset ul.header_menuset_sns {
    display: flex;
    justify-content: space-between;
    width: 128px;
    margin-left: auto;
    margin-bottom: 80px;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(1) img {
    width: 23.5px;
    height: 19px;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(2) img {
    width: 16.3px;
    height: 18.3px;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(3) img {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 960px) {
  .topPage .header {
    height: 0;
    padding: 0;
  }
  .topPage .header h1 img {
    transition: opacity 0.4s linear;
    opacity: 0;
    width: 0;
    overflow: hidden;
  }
  .header {
    width: auto;
    padding: 10.6666666667vw 8vw;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 10000;
    top: 0;
    width: 100%;
    mix-blend-mode: difference;
    background: transparent;
  }
  .header h1 img {
    transition: opacity 0.4s 0.4s linear;
    opacity: 1;
    width: 42.7706666667vw;
    height: 6.392vw;
  }
  .header ul.header_menu {
    display: none;
  }
  .header .header_btn {
    transform: translate(0, 0);
    transition: all 0.6s ease-in-out;
    width: 10.6666666667vw;
    height: 5.3333333333vw;
    display: inline-block;
    position: fixed;
    top: 11.7333333333vw;
    right: 8vw;
    cursor: pointer;
  }
  .header .header_btn:before {
    position: absolute;
    left: 0vw;
    top: 0vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #fff;
    content: "";
    transition: all 0.3s ease-in-out;
  }
  .header .header_btn:after {
    position: absolute;
    left: 0vw;
    top: 4vw;
    height: 1px;
    width: 10.6666666667vw;
    background: #fff;
    content: "";
    transition: all 0.3s ease-in-out;
  }
  .header_menuset {
    width: 61.0666666667vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10001;
    text-align: right;
    padding-top: 11.2vw;
    padding-right: 8vw;
    transition: all 0.6s ease-in-out;
    transform: translate(61.0666666667vw, 0);
  }
  .header_menuset.-show {
    transform: translate(0, 0);
  }
  .header_menuset .header_menuset_close {
    width: 10.6666666667vw;
    height: 5.3333333333vw;
    display: inline-block;
    position: relative;
    margin-bottom: 15.2vw;
    cursor: pointer;
  }
  .header_menuset .header_menuset_close:before {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #000;
    content: "";
    transform: rotate(25deg);
    transition: all 0.3s ease-in-out;
  }
  .header_menuset .header_menuset_close:after {
    position: absolute;
    left: -0.8vw;
    top: 2.6666666667vw;
    height: 1px;
    width: 12.2666666667vw;
    background: #000;
    content: "";
    transform: rotate(-25deg);
    transition: all 0.3s ease-in-out;
  }
  .header_menuset ul.header_menuset_menu {
    padding-right: 2.6666666667vw;
    margin-bottom: 21.3333333333vw;
  }
  .header_menuset ul.header_menuset_menu li {
    margin-bottom: 10.6666666667vw;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
  }
  .header_menuset ul.header_menuset_menu li:last-child {
    margin-bottom: 0;
  }
  .header_menuset p {
    padding-right: 2.6666666667vw;
    margin-bottom: 21.3333333333vw;
    font-size: 3.5vw;
    letter-spacing: 0.05rem;
  }
  .header_menuset ul.header_menuset_sns {
    padding-right: 2.6666666667vw;
    display: flex;
    justify-content: space-between;
    width: 34.1333333333vw;
    margin-left: auto;
    margin-bottom: 6.25vw;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(1) img {
    width: 6.2666666667vw;
    height: 5.0666666667vw;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(2) img {
    width: 4.3466666667vw;
    height: 4.88vw;
  }
  .header_menuset ul.header_menuset_sns li:nth-child(3) img {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
  }
}
.prlx {
  transform: translate(0, 10vw);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s cubic-bezier(0, 0, 1, 1);
}
.prlx.-show {
  transform: translate(0, 0);
  opacity: 1;
}

@media (min-width: 751px) {
  .base {
    transform: rotate(45deg);
    display: flex;
  }
  .base::after {
    content: "";
    clear: both;
    display: block;
  }
}