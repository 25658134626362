/* ==========================================================================
 *
 * Blocks - .header
 *
========================================================================== */
@media screen and (min-width:960px) {

    .worksPage .header ul.header_menu li:nth-child(1) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .detailPage .header ul.header_menu li:nth-child(1) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .presenPage .header ul.header_menu li:nth-child(1) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .aboutPage .header ul.header_menu li:nth-child(2) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .careersPage .header ul.header_menu li:nth-child(3) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .otherPage .header ul.header_menu li:nth-child(4) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .contactPage .header ul.header_menu li:nth-child(5) a::after {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    .topPage{
        .header{
            h1{
                img{
                    width:0;
                    overflow: hidden;
                }
            }
        }
    }
    .header {
        width: auto;
        padding:w(40px) w(58px) 0;
        display: flex;
        justify-content: space-between;
        position: fixed;
        z-index: 10000;
        top: 0;
        width: 100%;
        mix-blend-mode: difference;
        background: transparent;

        h1 {
            img {
                width: 171px;
                height: 25.5px;
            }
        }

        &.-min {
            ul.header_menu {
                transform: translate(0, -10vh)
            }

            .header_btn {
                transform: translate(0, 0vw)
            }
        }

        ul.header_menu {
            position: relative;
            transition: all 0.6s ease-in-out;
            display: flex;
            li {
                display: block;
                margin-left: 40px;

                &:last-child {
                    margin-left: 60px;
                }

                a {
                    position: relative;
                    display: inline-block;
                    text-decoration: none;
                    color: #fff;
                    font-size: 12px;
                    letter-spacing: 0.05rem;
                    //font-weight: 700;
                    line-height: 25.5px;
                    background: transparent;
                    cursor: pointer;
                }

                a::after {
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    transform: scale(0, 1);
                    transform-origin: right top;
                    transition: transform .4s;
                }

                a:hover::after {
                    transform-origin: left top;
                    transform: scale(1, 1);
                }
            }
        }

        .header_btn {
            transform: translate(0, -5vw);
            transition: all 0.6s ease-in-out;
            width: 40px;
            height: 20px;
            display: inline-block;
            position: absolute;
            top: w(40px);
            right: w(60px);
            cursor: pointer;

            &:before {
                position: absolute;
                left: w(0px);
                top: 5px;
                height: 1px;
                width: 40px;
                background: #fff;
                content: '';
                transition: all 0.3s ease-in-out;
                //transform: rotate(25deg);
            }

            &:after {
                position: absolute;
                left: w(0px);
                top: 15px;
                height: 1px;
                width: 40px;
                background: #fff;
                content: '';
                transition: all 0.3s ease-in-out;
                //transform: rotate(-25deg);
            }

            &:hover {
                &:before {
                    top: 15px;
                }

                &:after {
                    top: 5px;
                    //transform: rotate(-25deg);
                }
            }
        }
    }

    .header_menuset {
        width: calc(4.6875vw + 235px);
        height: 100vh;
        background: #fff;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10001;
        text-align: right;
        padding-top: w(40px);
        padding-right: w(60px);
        transition: all 0.6s ease-in-out;
        transform: translate(calc(4.6875vw + 235px), 0);

        &.-show {
            transform: translate(0, 0);
        }

        .header_menuset_close {
            width: 40px;
            height: 20px;
            display: inline-block;
            position: relative;
            margin-bottom: 65px;
            cursor: pointer;

            &:before {
                position: absolute;
                left: -3px;
                top: 10px;
                height: 1px;
                width: 46px;
                background: #000;
                content: '';
                transform: rotate(25deg);
                transition: all 0.3s ease-in-out;
            }

            &:after {
                position: absolute;
                left: -3px;
                top: 10px;
                height: 1px;
                width: 46px;
                background: #000;
                content: '';
                transform: rotate(-25deg);
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                &:before {
                    transform: rotate(-25deg);
                }

                &:after {
                    transform: rotate(25deg);
                    //transform: rotate(-25deg);
                }
            }
        }

        ul.header_menuset_menu {
            margin-bottom: 80px;

            li {
                margin-bottom: 40px;
                font-size: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        p {
            margin-bottom: 80px;
            font-size: 12px;
        }

        ul.header_menuset_sns {
            display: flex;
            justify-content: space-between;
            width: 128px;
            margin-left: auto;

            li {
                &:nth-child(1) {
                    img {
                        width: 23.5px;
                        height: 19px;
                    }
                }

                &:nth-child(2) {
                    img {
                        width: 16.3px;
                        height: 18.3px;
                    }
                }

                &:nth-child(3) {
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

            }

            margin-bottom:80px;
        }
    }
}



@media screen and (max-width:960px) {
    
    .topPage{
        .header{
            height: 0;
            padding: 0;
            h1{
                img{
                    transition:opacity 0.4s linear;
                    opacity: 0;
                    width:0;
                    overflow: hidden;
                }
            }
        }
    }
    .header {
        width: auto;
        padding: sw(40px) sw(30px);
        display: flex;
        justify-content: space-between;
        position: absolute;
        z-index: 10000;
        top: 0;
        width: 100%;
        mix-blend-mode: difference;
        background: transparent;

        h1 {
            img {
                transition:opacity 0.4s 0.4s linear;
                opacity: 1;
                width: sw(160.39px);
                height: sw(23.97px);
            }
        }

        ul.header_menu {
            display: none;
        }

        .header_btn {
            transform: translate(0, 0);
            transition: all 0.6s ease-in-out;
            width: sw(40px);
            height: sw(20px);
            display: inline-block;
            position: fixed;
            top: sw(44px);
            right: sw(30px);
            cursor: pointer;

            &:before {
                position: absolute;
                left: w(0px);
                top: sw(0px);
                height: 1px;
                width: sw(40px);
                background: #fff;
                content: '';
                transition: all 0.3s ease-in-out;
                //transform: rotate(25deg);
            }

            &:after {
                position: absolute;
                left: w(0px);
                top: sw(15px);
                height: 1px;
                width: sw(40px);
                background: #fff;
                content: '';
                transition: all 0.3s ease-in-out;
                //transform: rotate(-25deg);
            }
        }
    }

    .header_menuset {
        width: sw(229px);
        height: 100vh;
        background: #fff;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10001;
        text-align: right;
        padding-top: sw(42px);
        padding-right: sw(30px);
        transition: all 0.6s ease-in-out;
        transform: translate(61.0666666667vw, 0);

        &.-show {
            transform: translate(0, 0);
        }

        .header_menuset_close {
            width: sw(40px);
            height: sw(20px);
            display: inline-block;
            position: relative;
            margin-bottom: sw(57px);
            cursor: pointer;

            &:before {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #000;
                content: '';
                transform: rotate(25deg);
                transition: all 0.3s ease-in-out;
            }

            &:after {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #000;
                content: '';
                transform: rotate(-25deg);
                transition: all 0.3s ease-in-out;
            }
        }

        ul.header_menuset_menu {

        padding-right: sw(10px);
            margin-bottom: sw(80px);

            li {
                margin-bottom: sw(40px);
                font-size: 3.5vw;
                letter-spacing: 0.05rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        p {
            padding-right: sw(10px);
            margin-bottom: sw(80px);
            font-size: 3.5vw;
            letter-spacing: 0.05rem;
        }

        ul.header_menuset_sns {
            padding-right: sw(10px);
            display: flex;
            justify-content: space-between;
            width: sw(128px);
            margin-left: auto;

            li {
                &:nth-child(1) {
                    img {
                        width: sw(23.5px);
                        height: sw(19px);
                    }
                }

                &:nth-child(2) {
                    img {
                        width: sw(16.3px);
                        height: sw(18.3px);
                    }
                }

                &:nth-child(3) {
                    img {
                        width: sw(20px);
                        height: sw(20px);
                    }
                }

            }

            margin-bottom:w(80px);
        }
    }
}