/*
 * RESET
*/
body{ margin: 0; padding: 0; font-size: 62.5%; }
div,h1,h2,h3,h4,h5,h6,p,dl,dt,dd,ul,ol,li,blockquote,pre,table,caption,th,td,address,form,fieldset,legend,object,header,footer,section,article,nav,figure,canvas{ margin: 0; padding: 0; }
table{ border-spacing: 0; border-collapse: collapse; }
fieldset,img{ border: none; vertical-align: top; }
address,caption,cite,code,dfn,em,strong,th,var { font-weight: normal; font-style: normal; }
ol,ul,li{ list-style: none; }
caption,th,td{ vertical-align: top; text-align: left; }
h1,h2,h3,h4,h5,h6,small{ font-weight: normal; font-size: 100%; }
i{ font-style: normal; }
q:before,q:after{ content: ''; }
abbr,acronym{ border: none; }
sup{ vertical-align: top; }
sub{ vertical-align: baseline; }
a, object, embed{ outline: none; }
a:hover,a:active,a:focus{ outline: none; }
header,footer,section,article,nav,figure,canvas,main{ display: block; }