@media screen and (min-width:960px) {
    .works {
        padding-top: w(138px);

        nav {
            display: flex;
            justify-content: space-between;
            margin-left: w(58px);
            margin-right: w(58px);
            h2{
                display: none;
                font-family: nimbus-sans, sans-serif;
                font-size:12px;
                p{
                    margin-left:20px;
                    margin-right:40px;
                    position: relative;
                    top:3px;
                    display: inline-block;
                    font-size:20px;
                    font-family: YakuHanJP,YuGothic, "Yu Gothic Medium", "Yu Gothic",-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue,  Verdana, Meiryo, sans-serif;
                }
                a {
                    width: 20px;
                    height: 10px;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;

                    &:before {
                        position: absolute;
                        left: w(0px);
                        top: 5px;
                        height: 1px;
                        width: 20px;
                        background: #111;
                        content: '';
                        transition: all 0.3s ease-in-out;
                        transform: rotate(25deg);
                    }

                    &:after {
                        position: absolute;
                        left: w(0px);
                        top: 5px;
                        height: 1px;
                        width: 20px;
                        background: #111;
                        content: '';
                        transition: all 0.3s ease-in-out;
                        transform: rotate(-25deg);
                    }

                    &:hover {
                        &:before {
                        transform: rotate(-25deg);
                        }

                        &:after {
                        transform: rotate(25deg);
                        }
                    }
                }
            }
            .works-category,
            .works-type {
                display: flex;
                font-size: 12px;

                li {
                    margin-right: 50px;

                    a {
                        padding-bottom: 5px;
                        position: relative;
                        display: inline-block;
                        text-decoration: none;
                        color: #000;
                        font-size: 12px;
                        //font-weight: 700;
                        line-height: 20px;
                        background: transparent;
                        cursor: pointer;
                    }

                    a::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: #000;
                        transform: scale(0, 1);
                        transform-origin: right top;
                        transition: transform .3s;
                    }
                    a.-current::after{
                        transform-origin: left top;
                        transform: scale(1, 1);
                    }
                    a:hover::after {
                        transform-origin: left top;
                        transform: scale(1, 1);
                    }
                     input{
                        background: transparent;
                        outline: none;
                        border: none;
                        border-bottom:1px solid #000;
                        width: s(184);
                        font-size: 12px;
                        padding-bottom: s(8);
                     }
                     input::placeholder {
                      color: #000;
                    }
                    &.works-search{
                        position: relative;
                        top:2px;
                        img{
                            position: absolute;
                            right: 0;
                            top:50%;
                            transform:translate(0,-66%);
                            width: 32px;
                            padding: 10px;
                            cursor: pointer;
                            &:hover{
                                opacity: 0.4;
                            }
                        }
                    }
                }
            }

            .works-type {
                display: none;
            }

            .works-btn {
                cursor: pointer;

                h3 {
                    position: relative;
                    line-height: 25.5px;
                    font-size: 12px;

                    img {
                        width: 25px;
                        height: 10px;
                        margin-top: 8px;
                        margin-right: 10px;
                        transition: all 0.4s ease-in-out;
                    }

                    &:hover {
                        img {
                            transform: rotate(-180deg);
                        }
                    }
                }

                text-align: right;

            }

            margin-bottom: 71px;
        }

        .works-slide {
            padding: 0 0 0 w(58px);

            .slick-list {
                overflow: visible;
            }

            .works-slide-element {
                padding-right: 80px;
                height: 419px;

                img {
                    width: 518px;
                    height: 303px;

                }

                .hov-image {
                    margin-bottom: 19px;
                    display: inline-block;
                }

                .works-slide-name {
                    font-size: 14px;
                    line-height: 1.5;
                    font-weight: bold;
                    margin-bottom: 7px;
                }

                .works-slide-kana {
                    font-size: 13px;
                    line-height: 1.5;
                }

            }

            .slick-prev {
                position: absolute;
                top: 0px;
                height: 303px;
                z-index: 10;
                left: 0;
                width: 10%;
                opacity: 0;
            }

            .slick-next {
                position: absolute;
                top: 0px;
                right: 0;
                height: 303px;
                z-index: 10;
                width: 10%;
                opacity: 0;
            }

            margin-bottom: 22px;
        }

        //２枚バージョン
        .works-list {
            //width:w(1144px);
            margin: 0 w(58px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            span {
                content: '';
                display: inline-block;
                width: 46.15%;
            }

            .works-list-element {
                width: 46.15%;
                //height: w(265px);
                margin-bottom: 100px;

                img {
                    //width:w(500px);
                    width: 100%;
                }
                .hov-image{
                    margin-bottom: 20px;
                }

                .works-list-name {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 7px;
                    line-height: 1.5;
                }

                .works-list-kana {
                    font-size: 13px;
                    line-height: 1.5;
                }
            }
        }
        .presen-list{
            margin-left: w(87px);
            margin-right: w(87px);

            li{
                //a{
                    img{
                        width:100%;
                    }
                //}
                iframe{
                    width:w(1106px);
                    height: w(622px);
                    margin-bottom:20px;
                }
                p a{
                    font-size:12px;
                    line-height: 18px;
                    height: 18px;
                    background-color: #fff;
                    padding:0 10px;
                    display: inline-block;
                    &:hover{
                        background-color:rgba(255,255,255,0.8);
                    }
                }
                margin-bottom: w(80px);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .works {
        .works-list {
            span {
                width: 30%;
            }

            .works-list-element {
                width: 30%;
            }
        }
    }
    .works .works-slide .works-slide-element {
        height: calc(23.671875vw + 116px);
    }
    .works .works-slide .works-slide-element img {
        width: w(518px);
        height: w(303px);
    }
}

@media screen and (min-width:1280px) {
    .works {
        .works-list {
            span {
                width: 30%;
            }

            .works-list-element {
                width: 30%;
            }
        }
    }
    .works .works-slide .works-slide-element {
        height: calc(23.671875vw + 116px);
    }
    .works .works-slide .works-slide-element img {
        width: w(518px);
        height: w(303px);
    }
}

@media screen and (min-width:1900px) {
    .works {
        .works-list {
            span {
                width: 22.2222%;
            }

            .works-list-element {
                width: 22.2222%;
            }
        }
    }
}

@media screen and (max-width:960px) {
    .works {
        padding-top: 30vw;

        nav {
            h2{
                display: none;
                padding-left: sw(40px);

                font-family: nimbus-sans, sans-serif;
                font-size:sw(12px);
                p{
                    margin-left:sw(20px);
                    margin-right:sw(40px);
                    position: relative;
                    top:sw(3px);
                    display: inline-block;
                    font-size:sw(20px);
                    font-family: YakuHanJP,YuGothic, "Yu Gothic Medium", "Yu Gothic",-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue,  Verdana, Meiryo, sans-serif;
                }
                a {
                    width: sw(20px);
                    height: sw(10px);
                    display: inline-block;
                    position: relative;
                    cursor: pointer;

                    &:before {
                        position: absolute;
                        left: w(0px);
                        top: sw(5px);
                        height: 1px;
                        width: sw(20px);;
                        background: #111;
                        content: '';
                        transition: all 0.3s ease-in-out;
                        transform: rotate(25deg);
                    }

                    &:after {
                        position: absolute;
                        left: w(0px);
                        top: sw(5px);;
                        height: 1px;
                        width: sw(20px);;
                        background: #111;
                        content: '';
                        transition: all 0.3s ease-in-out;
                        transform: rotate(-25deg);
                    }
                }
            }
            .works-category,
            .works-type {
                display: flex;
                padding-left: sw(40px);
                width: 100%;
                overflow: auto;
                &::-webkit-scrollbar {
                      display: none;
                      -webkit-appearance: none;
                }
                font-size: 3.5vw;
                letter-spacing: 0.05rem;

                li {
                    &:last-child{
                        padding-right: sw(40px);
                    }
                    margin-right: sw(40px);

                    a {
                        padding-bottom: sw(10px);
                        position: relative;
                        display: inline-block;
                        text-decoration: none;
                        color: #000;
                        font-size: 3.5vw;
                        letter-spacing: 0.05rem;
                        //font-weight: 700;
                        line-height: sw(25.5px);
                        background: transparent;
                        cursor: pointer;
                    }
                    a::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: #000;
                        transform: scale(0, 1);
                        transform-origin: right top;
                    }
                    a.-current::after{
                        transform-origin: left top;
                        transform: scale(1, 1);
                    }
                }

                margin-bottom: 7vw;
            }

            .works-type {
                display: none;
            }

            .works-btn {
                cursor: pointer;
                margin-left: sw(40px);

                h3 {
                    position: relative;
                    line-height: sw(25.5px);
                    font-size: 3.5vw;
                    letter-spacing: 0.05rem;

                    img {
                        width: sw(25px);
                        height: sw(10px);
                        margin-top: sw(8px);
                        margin-right: sw(10px);
                        transition: all 0.4s ease-in-out;
                    }
                }

                font-size: 3.5vw;
                letter-spacing: 0.05rem;
                text-align: left;

                ul {
                    display: none;
                }

            }

             input{
                        background: transparent;
                        outline: none;
                        border: none;
                        border-bottom:1px solid #000;
                        width: sw(146);
                        font-size: 3.5vw;
                        padding-bottom: s(8);
                     }
                     input::placeholder {
                      color: #000;
                    }
                    .works-search{
                        margin-left: sw(40px);
                        margin-bottom: sw(60px);
                        position: relative;
                        img{
                            position: absolute;
                            left: sw(130);
                            top:50%;
                            padding-top: sw(10);
                            transform:translate(0,-100%);
                            width: sw(12px);
                        }
                        &.-hide{
                            display: none;
                        }
                    }

            margin-bottom: 10vw;
        }

        .works-slide {
            
            width: 100%;
            overflow: hidden;
            .works-slide-element {
                //padding-left:sw(25px);
                width: sw(345px);
                //height: sw(249px);

                img {
                    width: sw(335px);
                    height: sw(196px);
                }

                .hov-image {
                    margin-bottom: sw(10px);
                    display: inline-block;
                }

                .works-slide-name {
                    font-size: sw(12px);
                    line-height: 1.5;
                    font-weight: bold;
                    padding-left: sw(20px);
                    margin-bottom: 1vw;
                }

                .works-slide-kana {
                    padding-left: sw(20px);
                    font-size: sw(12px);
                    line-height: 1.5;
                }

            }

            .slick-prev {
                position: absolute;
                top: 0px;
                height: sw(303px);
                z-index: 10;
                left: 0;
                width: 0%;
                opacity: 0;
            }

            .slick-next {
                position: absolute;
                top: 0px;
                right: 0;
                height: sw(303px);
                z-index: 10;
                width: 0%;
                opacity: 0;
            }

            margin-bottom: 10vw;
        }

        .works-list {
            width: auto;
            margin: 0 sw(40px);

            .works-list-element {
                width: auto;
                height: sw(265px);
                margin-bottom: 0;

                img {
                    width: sw(295px);
                    margin-bottom: 4vw;
                }

                .works-list-name {
                    font-size: sw(12px);
                    font-weight: bold;
                    line-height: 1.5;
                }

                .works-list-kana {
                    font-size: sw(12px);
                    line-height: 1.5;
                }
            }
        }
        
        .presen-list{
            margin-left: sw(40px);
            margin-right: sw(40px);

            li{
                a{
                    img{
                        width:100%;
                    }
                }
                p a{
                    font-size:sw(10px);
                    line-height: sw(15px);
                    height: sw(15px);
                    background-color: #fff;
                }
                iframe{
                    width:sw(295px);
                    height: sw(166px);
                    margin-bottom:5vw;
                }
                margin-bottom: sw(40px);
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}