
@media screen and (min-width:960px){
	.-sp{
		display: none;
	}
	section,#container{
		will-change: transform;
	}
}
@media screen and (max-width:960px){
	.-pc{
		display: none;
	}
}
.about-member-img img {
  pointer-events: none;
  user-select: none;
}
#overlay{
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
	display: none;
}
#trans_layer_1{
	position: fixed;
	bottom:0;
	left:0;
	width:100%;
	height: 0%;
	background:#eee;
	will-change: transform;
	&.-trans1{
		height: 100%;
		transition:height 0.4s cubic-bezier(.86, 0, .07, 1);
	}
	&.-trans1.-trans2{
		top:0;
		height: 0%;
		transition:height 0.4s cubic-bezier(.86, 0, .07, 1);
	}
}
#trans_layer_2{
	position: fixed;
	bottom:0;
	left:0;
	width:100%;
	height: 0%;
	background:#fff;
	will-change: transform;

	&.-trans2{
		height: 100%;
		opacity: 0;
		transition:height 0.4s cubic-bezier(.86, 0, .07, 1) ,opacity 0.4s 0.3s ease-out ;
	}
}


* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-box-shadow: none;
    box-shadow: none;   
    outline: none;
}

body {
	font-size: 16px;
	line-height: 1;
	background:#F5F5F5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: YakuHanJP,YuGothic, "Yu Gothic Medium", "Yu Gothic",-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue,  Verdana, Meiryo, sans-serif;
}
.-font{
	font-family: nimbus-sans,sans-serif;
	*{
		font-family: nimbus-sans, sans-serif;
	}
}
#container{
	background:#F5F5F5;
}
@media screen and (min-width:960px){
	[data-device="sp"]{
		display: none!important;
	}
}
@media screen and (max-width:960px){
	[data-device="pc"]{
		display: none!important;
	}
}

@media screen and (min-width:960px){
.hov-image{
	img{
		transition:ease-out 0.4s transform;
	}
	cursor: none;
	overflow: hidden;
	&:hover{
		img{
			transform:scale(1.1);
		}
	}
}
}
.slick-prev,.slick-next{
	cursor: none;
}

body,html{
	height: 100%;
  //min-height: w(712px);//w(644px);
}
img{
	max-width: 100%;
}

*{
	 box-sizing: border-box;
}

a{
	color:#000;
	text-decoration: none;
}

.prlx{
    transform:translate(0,40px);
    opacity:0;
    transition:all 0.6s ease-out,opacity 0.6s linear;
    &.-show{
        transform:translate(0,0);
        opacity:1;
    }
}

@media screen and (min-width:960px){
	#cursor {
	    width: 20px;
	    height: 20px;
	    background: #01A0E9;
	    background: #000;
	    border-radius: 50%;
	    pointer-events: none;
	    position: absolute;
	    top: -400px;
	    left: -400px;
	    display: none;
	}

	#cursor_scrl {
	    width: 20px;
	    height: 20px;
	    border-radius: 50%;
	    pointer-events: none;
	    position: absolute;
	    top: -400px;
	    left: -400px;

	    span {
	    	&.-left{
	    		&:nth-child(1) {
	    			opacity: 1;
	            	right: w(10px);
	            	transition:all 0.2s ease-out;
	            	&:before{
	            		transform: rotate(13deg);
	            	}
					&:after{
						transform: rotate(-13deg);
					}
	    		}
	    	}
	    	&.-right{
	    		&:nth-child(2) {
	    			opacity: 1;
	            	left: w(10px);
	            	transition:all 0.2s ease-out;
	            	&:before{
	            		transform: rotate(13deg);
	            	}
					&:after{
						transform: rotate(-13deg);
					}
	    		}
	    	}
	        &:nth-child(1) {
	            position: absolute;
	            top: 0;
	            right: w(0px);
	            width: w(40px);
	            height: w(20px);
	            opacity: 0;
	            //background: orange;
	            &:before{
					position: absolute;
					left:w(0px);
					top:w(10px);
					height:1px;
					width:w(40px);
					background: #000;
					content:'';
					transform: rotate(0deg);
					transition:all 0.2s ease-in-out;
					transform-origin: 0% 0;
				}
				&:after{
					position: absolute;
					left:w(0px);
					top:w(10px);
					height:1px;
					width:w(40px);
					background: #000;
					content:'';
					transform: rotate(0deg);
					transition:all 0.2s ease-in-out;
					transform-origin: 0% 0;
				}
	        }

	        &:nth-child(2) {
	            position: absolute;
	            top: 0;
	            left: w(0px);
	            width: w(40px);
	            height: w(20px);
	            opacity: 0;
	            //background: orange;
	            &:before{
					position: absolute;
					left:w(0px);
					top:w(10px);
					height:1px;
					width:w(40px);
					background: #000;
					content:'';
					transform: rotate(0deg);
					transition:all 0.2s ease-in-out;
					transform-origin: 100% 0;
				}
				&:after{
					position: absolute;
					left:w(0px);
					top:w(10px);
					height:1px;
					width:w(40px);
					background: #000;
					content:'';
					transform: rotate(0deg);
					transition:all 0.2s ease-in-out;
					transform-origin: 100% 0;
				}
	        }
	    }
	}

}

