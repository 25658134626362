/* ==========================================================================
 *
 * Utility: Extend - layout
 *
mediaの外で作る必要がある
.base{
	@extend %center
}
========================================================================== */

%center{
	margin-left:auto;
	margin-right:auto;
}

%wrapper {
}

%inner {
}
