@media screen and (min-width:960px){
    .loader {
        height: 100%;
        width: 100%;
        background: #fff;
        z-index:10000;
        position: fixed;
        top:0;
        left:0;
        img{
            width:w(350px);
            position: absolute;
            top:50%;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
        }
    }
}
@media screen and (max-width:960px){
    .loader {
        height: 100%;
        width: 100%;
        background: #fff;
        z-index:10000;
        position: fixed;
        top:0;
        left:0;
        img{
            width:sw(270px);
            position: absolute;
            top:50%;
            top:calc(var(--vh, 1vh) * 50);
            left:50%;
            transform:translate(-50%,-50%);
        }
    }
}