@media screen and (min-width:960px){
.contact{
	background:#F5F5F5;
	.contact_visual{
		background:url(../img/contact_bg.png) top center;
		background-size:cover;
		height:80vh;
		margin-bottom: 80px;
	}
	.contact-company{
		width:w(980px);
		margin:0 auto 140px;
		position: relative;
		dl{
			margin-left: 0px;
			dt{
				float:left;
				clear: both;
				width:w(166px);
				margin-right: 0px;
				font-size: 14px;
				line-height: 2;
			}
			dd{
				float:left;
				width:w(400px);
				font-size: 14px;
				line-height: 2;
				margin-bottom: 22px;
			}
			&:after{
				content:'';
				display: block;
				clear: both;
			}
		}
	}
	.contact-map{
		//width:w(980px);
		height:555px;
		> div{
		//width:w(980px);
		width:100%;
		height:555px;
		}
		margin:0 w(58px) 10px;
	}
	.contact-map-link{
		font-size:10px;
		line-height: 15px;
		height: 15px;
		margin-left:w(58px);
		padding:0 10px;
		display: inline-block;
		background-color: #fff;
					&:hover{
						background-color:rgba(255,255,255,0.8);
					}
	}
}
}



@media screen and (max-width:960px){
.contact{
	background:#F5F5F5;
	.contact_visual{
		background:url(../img/contact_bg.png) center;
		background-size:cover;
		height:80vh;
		margin-bottom: sw(62px);
	}
	.contact-company{
		width:auto;
		margin:0 sw(40px);
		position: relative;
		dl{
			margin-left: 0px;
			dt{
				float:left;
				clear: both;
				width:sw(83px);
				margin-right: 0px;
				font-size: sw(13px);
				line-height: 2;
			}
			dd{
				float:left;
				width:sw(212px);
				font-size: sw(13px);
				line-height: 2;
				margin-bottom: sw(22px);
			}
			&:after{
				content:'';
				display: block;
				clear: both;
			}
		}
	}
	.contact-map{
		width:sw(295px);
		height:sw(295px);
		> div{
		width:sw(295px);
		height:sw(295px);

		}
		margin:0 auto w(10px);
	}
	.contact-map-link{
		font-size:sw(10px);
		line-height: sw(15px);
		height: sw(15px);
		margin-left:sw(40px);
		padding:0 sw(10px);
		display: inline-block;
		background-color: #fff;
	}
}
}



