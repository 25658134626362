@media screen and (min-width:960px){
.top {
    height: 100vh;
    overflow: hidden;
    position: relative;

    h1 {
        position: absolute;
        bottom: w(104.3px);
        right: w(145.5px);
        width: w(989px);
        z-index: 1;
        pointer-events: none;

        img {
            width: 100%;
        }
    }
    /*
    .top_news {
      position: absolute;
      top: 17.5vh;
      left: 13.046875vw;
      font-size: 0.9375vw;
      color: #fff;
      z-index: 1;
      padding-bottom: 0.9375vw;
      border-bottom: 1px solid #fff;
      mix-blend-mode: difference;
      cursor: pointer;
    }
    .top_news a {
        color: #fff;
      }
    .top_news:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0px;
      background: #fff;
      transition: all ease-out 0.16s;
      display: block;
      mix-blend-mode: difference;
    }
    .top_news:hover:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2.8125vw;
      background: #fff;
      display: block;
      mix-blend-mode: difference;
    }
    */
    
    .top_news {
        position: absolute;
        top: h(80px);
        font-size: s(18px);
        font-weight: bold;
        letter-spacing: 0.05rem;
        line-height: 1.8;
        color: #fff;
        z-index: 1;
        width: s(415);
        padding-top: s(16px);
        padding-bottom: s(16px);
        padding-right: s(24px);
        background: #000;
        text-align: right;
        cursor: pointer;
        a{
            color:#fff;
            font-weight: 500;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left:0;
            width: 100%;
            height: 0px;
            background: #fff;
            transition: all ease-out 0.16s;
            display: block;
            mix-blend-mode: difference;
        }

        &:hover {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                display: block;
                mix-blend-mode: difference;
            }
        }
        
    }

    .top_content {
        height: 0%;
        width: 100%;
        overflow: hidden;
        transition: linear 0.01s 1s all;



        .gallery {
            transform: scale(0.7);
            opacity: 0;
            cursor: none;
            will-change: transform;
            //border:1px solid #000;
            transition: cubic-bezier(.175, .885, .32, 1.275) 1s all;
        }

        &.-show {
            height: 100%;

            .gallery {
                transition: cubic-bezier(.175, .885, .32, 1.275) 1s 1s all;
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    .anim1 {
        width: t(20000px);
        height: t(700px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;

        position: relative;
        top: calc(50% - 18.2291666666vw);

        div {
            margin-right: t(180px);
            position: relative;
        }

        .gallery {
            .-w {
                width: t(700px);
                height: auto;

                &.-s90 {
                    width: calc(36.4583333333vw * 0.9);
                }
                &.-s80 {
                    width: calc(36.4583333333vw * 0.8);
                }
                &.-s70 {
                    width: calc(36.4583333333vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: t(700px);

                &.-s90 {
                    height: calc(36.4583333333vw * 0.9);
                }

                &.-s80 {
                    height: calc(36.4583333333vw * 0.8);
                }

                &.-s70 {
                    height: calc(36.4583333333vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
    }

    .anim2_1 {
        width: 10000px;
        height: t(350px);
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        position: absolute;
        bottom: calc(50% + 4.6875vw * 0.5);

        div {
            margin-right: t(90px);
            position: relative;
        }

        .gallery {
            .-w {
                width: 18.2291666667vw;
                height: auto;

                &.-s90 {
                    width: calc(18.2291666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(18.2291666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(18.2291666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: 18.2291666667vw;

                &.-s90 {
                    height: calc(18.2291666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(18.2291666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(18.2291666667vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
        //animation:scroll 24s linear infinite reverse;
    }


    .anim2_2 {
        width: 10000px;
        height: t(350px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;

        position: absolute;
        top: calc(50% + 4.6875vw * 0.5);

        div {
            margin-right: t(90px);
            position: relative;
        }

        .gallery {
            .-w {
                width: 18.2291666667vw;
                height: auto;

                &.-s90 {
                    width: calc(18.2291666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(18.2291666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(18.2291666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: 18.2291666667vw;

                &.-s90 {
                    height: calc(18.2291666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(18.2291666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(18.2291666667vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
        //animation:scroll 24s linear infinite;
    }

    .rotate {
        transform: rotate(30deg);
        width: 100%;
        height: 100%;
        //text-align: center;
    }

    .anim3 {
        height: t(10000px);
        width: t(800px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        position: relative;
        left: calc(50% - 20.888888888vw);

        div {
            margin-bottom: t(206px);
            position: relative;
        }

        .gallery {
            .-w {
                width: t(800px);
                height: auto;

                &.-s90 {
                    width: calc(41.6666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(41.6666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(41.6666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: t(800px);

                &.-s90 {
                    height: calc(41.6666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(41.6666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(41.6666666667vw * 0.7);
                }
            }
            //text-align: center;
            p {
                display: inline-block;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite;
    }

    .anim4_1 {
        height: 10000px;
        width: t(560px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        text-align: center;
        position: absolute;
        right: calc(50% + 70px);

        div {
            margin-bottom: t(140px);
            position: relative;
        }

        .gallery {
            .-w {
                width: 29.1666666667vw;
                height: auto;

                &.-s90 {
                    width: calc(29.1666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(29.1666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(29.1666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: 29.1666666667vw;

                &.-s90 {
                    height: calc(29.1666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(29.1666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(29.1666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite;
    }

    .anim4_2 {
        height: 10000px;
        width: 560px;
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        position: absolute;
        left: calc(50% + 70px);
        text-align: center;
        div {
            margin-bottom: t(140px);
            position: relative;
        }

        .gallery {
            .-w {
                width: 29.1666666667vw;
                height: auto;

                &.-s90 {
                    width: calc(29.1666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(29.1666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(29.1666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: 29.1666666667vw;

                &.-s90 {
                    height: calc(29.1666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(29.1666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(29.1666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite reverse;
    }

}

.top_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;

    .top_layer_image {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: h(590px);

        img {
            height: h(590px);
            width: auto;
            max-width: unset;
        }

        p {
            font-size: w(10px);
            margin-top: w(10px);
            color: #fff;
            font-weight: bold;
        }

        .top_layer_close {
            width: 40px;
            height: 20px;
            display: inline-block;
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translate(-50%, 0);
            cursor: pointer;

            &:before {
                position: absolute;
                left: -3px;
                top: 10px;
                height: 1px;
                width: 46px;
                background: #fff;
                content: '';
                transform: rotate(25deg);
                transition: all 0.3s ease-in-out;
            }

            &:after {
                position: absolute;
                left: -3px;
                top: 10px;
                height: 1px;
                width: 46px;
                background: #fff;
                content: '';
                transform: rotate(-25deg);
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                &:before {
                    transform: rotate(-25deg);
                }

                &:after {
                    transform: rotate(25deg);
                    //transform: rotate(-25deg);
                }
            }
        }
    }
}

@keyframes scroll {
    0% {
        transform: translate(0px, 0);
    }

    100% {
        transform: translate(-2000px, 0);
    }
}

@keyframes scrollv {
    0% {
        transform: translate(0px, 0);
    }

    100% {
        transform: translate(0px, -2000px);
    }
}
}


@media screen and (max-width:960px){
.top {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;

    h1 {
        position: absolute;
        top: 50vh;
        top: calc(var(--vh, 1vh) * 50);
        transform:translate(0,-50%);
        //top: calc(var(--vh, 1vh) * 50 - 44.8vw);
        left: sw(10px);
        width: sw(355px);
        z-index: 1;
        pointer-events: none;

        img {
            width: 100%;
        }
    }
    
    .top_news {
        position: absolute;
        top: sw(23px);
        left: sw(0px);
        font-size: sw(12px);
        font-weight: 500;
        letter-spacing: 0.05rem;
        color: #fff;
        z-index: 1;
        line-height: 1.8;
        padding-top: sw(16px);
        padding-bottom: sw(16px);
        color: #fff;
        background: #01A0E9;
        background: #000;
        width: sw(248);
        text-align: center;
        cursor: pointer;
        a{
            color:#fff;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 0px;
            background: #fff;
            transition: all ease-out 0.16s;
            display: block;
            mix-blend-mode: difference;
        }
    }
    /*
    .top_news {
    position: absolute;
    top: 5.223880597vh;
    left: 8vw;
    font-size: 3.2vw;
    letter-spacing: 0.05rem;
    color: #fff;
    z-index: 1;
    padding-bottom: 3.2vw;
    border-bottom: 1px solid #fff;
    mix-blend-mode: difference;
    cursor: pointer;
  }
  .top_news a {
    color: #fff;
  }
  .top_news:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background: #fff;
    transition: all ease-out 0.16s;
    display: block;
    mix-blend-mode: difference;
  }*/

    .top_content {
        height: 0%;
        width: 100%;
        overflow: hidden;
        transition: linear 0.01s 1s all;
        position: relative;
        left:-99999px;

        .gallery {
            transform: scale(0.7);
            opacity: 0;
            cursor: none;
            will-change: transform;
            //border:1px solid #000;
            transition: cubic-bezier(.175, .885, .32, 1.275) 1s all;
        }

        &.-show {
            height: 100%;
            left:0;
            .gallery {
                transition: cubic-bezier(.175, .885, .32, 1.275) 1s 1s all;
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    .anim1 {
        width: sw(20000px);
        height: sw(336px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;

        position: relative;
        top: calc(50vh - 44.8vw);
        top: calc(var(--vh, 1vh) * 50 - 44.8vw);

        div {
            margin-right: sw(97px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(336px);
                height: auto;

                &.-s90 {
                    width: calc(89.6vw * 0.9);
                }
                &.-s80 {
                    width: calc(89.6vw * 0.8);
                }
                &.-s70 {
                    width: calc(89.6vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(336px);

                &.-s90 {
                    height: calc(89.6vw * 0.9);
                }

                &.-s80 {
                    height: calc(89.6vw * 0.8);
                }

                &.-s70 {
                    height: calc(89.6vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
    }

    .anim2_1 {
        width: 10000px;
        height: sw(196px);
        white-space: nowrap;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        position: absolute;
        bottom: calc(50vh + 13.3333333333vw * 0.5);
        bottom: calc(var(--vh, 1vh) * 50 + 13.3333333333vw * 0.5);

        div {
            margin-right: sw(50px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(196px);
                height: auto;

                &.-s90 {
                    width: calc(52.2666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(52.2666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(52.2666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(196px);

                &.-s90 {
                    height: calc(52.2666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(52.2666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(52.2666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
        //animation:scroll 24s linear infinite reverse;
    }


    .anim2_2 {
        width: 10000px;
        height: sw(196px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;

        position: absolute;
        top: calc(50vh + 13.3333333333vw * 0.5);
        top: calc(var(--vh, 1vh) * 50 + 13.3333333333vw * 0.5);

        div {
            margin-right: sw(50px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(196px);
                height: auto;

                &.-s90 {
                    width: calc(52.2666666667vw* 0.9);
                }

                &.-s80 {
                    width: calc(52.2666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(52.2666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(196px);

                &.-s90 {
                    height: calc(52.2666666667vw* 0.9);
                }

                &.-s80 {
                    height: calc(52.2666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(52.2666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        will-change: transform;
        //animation:scroll 24s linear infinite;
    }

    .rotate {
        transform: rotate(30deg);
        width: 100%;
        height: 100%;
            text-align: center;
    }

    .anim3 {
        height: sw(10000px);
        width: sw(336px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        position: relative;
        left: calc(50% - 44.8vw);

        div {
            margin-bottom: sw(87px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(336px);
                height: auto;

                &.-s90 {
                    width: calc(89.6vw * 0.9);
                }

                &.-s80 {
                    width: calc(89.6vw * 0.8);
                }

                &.-s70 {
                    width: calc(89.6vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(336px);

                &.-s90 {
                    height: calc(89.6vw * 0.9);
                }

                &.-s80 {
                    height: calc(89.6vw * 0.8);
                }

                &.-s70 {
                    height: calc(89.6vw * 0.7);
                }
            }
            //text-align: center;
            p {
                display: block;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite;
    }

    .anim4_1 {
        height: 10000px;
        width: sw(241px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        position: absolute;
        right: calc(50% + 11.4666666667vw);

        div {
            margin-bottom: sw(62px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(241px);
                height: auto;

                &.-s90 {
                    width: calc(64.2666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(64.2666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(64.2666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(241px);

                &.-s90 {
                    height: calc(64.2666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(64.2666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(64.2666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite;
    }

    .anim4_2 {
        height: 10000px;
        width: sw(241px);
        //overflow: hidden;
        white-space: nowrap;
        //justify-content: space-between;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: nowrap;
        flex-flow: column;

        position: absolute;
        left: calc(50% + 11.4666666667vw);

        div {
            margin-bottom: sw(62px);
            position: relative;
        }

        .gallery {
            .-w {
                width: sw(241px);
                height: auto;

                &.-s90 {
                    width: calc(64.2666666667vw * 0.9);
                }

                &.-s80 {
                    width: calc(64.2666666667vw * 0.8);
                }

                &.-s70 {
                    width: calc(64.2666666667vw * 0.7);
                }
            }

            .-h {
                width: auto;
                height: sw(241px);

                &.-s90 {
                    height: calc(64.2666666667vw * 0.9);
                }

                &.-s80 {
                    height: calc(64.2666666667vw * 0.8);
                }

                &.-s70 {
                    height: calc(64.2666666667vw * 0.7);
                }
            }

            p {
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                text-align: center;
            }
        }

        will-change: transform;
        //animation:scrollv 24s linear infinite reverse;
    }

}

.top_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;

    .top_layer_image {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: sw(295px);

        img {
            height: auto;
            width: sw(295px);
            max-width: unset;
        }

        p {
            font-size: sw(10px);
            margin-top: sw(10px);
            color: #fff;
            font-weight: bold;
        }

        .top_layer_close {
            width: sw(40px);
            height: sw(20px);
            display: inline-block;
            position: absolute;
            top: sw(-30px);
            left: 50%;
            transform: translate(-50%, 0);
            cursor: pointer;

            &:before {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #fff;
                content: '';
                transform: rotate(25deg);
                transition: all 0.3s ease-in-out;
            }

            &:after {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #fff;
                content: '';
                transform: rotate(-25deg);
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

@keyframes scroll {
    0% {
        transform: translate(0px, 0);
    }

    100% {
        transform: translate(-2000px, 0);
    }
}

@keyframes scrollv {
    0% {
        transform: translate(0px, 0);
    }

    100% {
        transform: translate(0px, -2000px);
    }
}
}