@media screen and (min-width:960px){
.other{
	background:#F5F5F5;
	padding-top:w(266px);
	
	h1{
		font-size:22px;
		text-align: center;
		font-weight: bold;
		margin-bottom: 40px;
	}
	.other-h1-text{
		p{
			text-align: center;
			//line-height: 1.8;
			line-height:2;
			font-size: 15px;
			padding-bottom: 133px;
		}
	}
	.other-list{
		width:w(980px);
		margin:0 auto;
		ul{
			li{
				position: relative;
				height: w(283px);
				width:100%;
				margin-bottom:140px;
				img{
					position: absolute;
					right:0;
					width: w(482px);
					height: w(283px);
				}
				.other-list-name{
					font-size:20px;
					margin-bottom:20px;
					width: w(400px);
				}
				.other-list-text{
					//font-size:10px;
					width: w(400px);
					margin-bottom: 30px;
					//line-height: 1.83333333;
					font-size:14px;
					line-height: 2;
				}
				.other-list-link{
					font-size:12px;
					line-height: 18px;
					height: 18px;
					background-color: #fff;
					padding:0 10px;
					display: inline-block;
					&:hover{
						background-color:rgba(255,255,255,0.8);
					}
				}
				p{
					margin-bottom:10px;
				}

			}
		}
	}
}
}

@media screen and (max-width:960px){
.other{
	background:#F5F5F5;
	padding-top:35vw;
	
	h1{
		font-size:sw(22px);
		text-align: center;
		font-weight: bold;
		margin-bottom: 10vw;
	}
	.other-h1-text{
		p{
			text-align: center;
			//line-height: 1.8;
			line-height:2;
			font-size: sw(15px);
			margin:0 sw(40px);
			padding-bottom: 15vw;
		}
	}
	.other-list{
		width:auto;
		margin:0 auto;
		ul{
			margin:0 sw(40px);
			li{
				position: relative;
				width:auto;
				margin-bottom:sw(80px);
				&:last-child{
					margin-bottom:0;
				}
				img{
					//position: absolute;
					//right:0;
					margin-bottom: sw(40px);
				}
				.other-list-name{
					font-size:sw(20px);
					margin-bottom:5vw;
					width: auto;
				}
				.other-list-text{
					font-size:sw(12px);
					width: auto;
					line-height: 1.8333333;
					margin-bottom:sw(30px);
				}
				.other-list-link{
					font-size:sw(10px);
					line-height: sw(15px);
					height: sw(15px);
					background-color: #fff;
				}
				p{
					margin-bottom:5vw;
				}
			}
		}
	}
}
}

