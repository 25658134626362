@media screen and (min-width:960px){
.detail{
	background:#F5F5F5;
	.detail_visual{
		height: 56.25vw;
		margin-bottom: 80px;
	}
	.detail_title{
		width:648px;
		margin:0 auto 60px;
		h1{
			font-size:20px;
			line-height: 1.2;
			font-weight: bold;
			margin-bottom: 11px;
		}
		h2{
			font-size:16px;
			line-height: 2;
		}
	}
	.detail_text{
		width:648px;
		margin:0 auto 81px;
		h3{
			font-size:15px;
			font-weight: bold;
			line-height: 1.2;
			margin-bottom: 20px;
		}
		p{
			font-size:14px;
			//line-height: 1.833333;
			line-height: 2;
		}
	}
	iframe{
		width:w(1106px);
		height:w(622px);
		margin:0 auto 10px;
		display: block;
	}
	.detail_image{
		width:w(1106px);
		margin:0 auto 68px;
		img{
			margin-bottom: 10px;
			width:100%;
		}
		p{
			color:#777777;
			font-size:11px;
			line-height: 1.8;
		}
	}
	.detail-list{
		padding:0 0 0  w(58px);
		margin-bottom:80px;
		overflow: hidden;
		.slick-list{
			overflow: visible;
		}
		img{
			width:auto;
			//width:w(912px);
			height: w(520px);
			margin-right:w(40px);
			margin-bottom: 10px;
		}
		p{
			//margin-left:w(40px;
			color:#777777;
			font-size:10px;

		}

	  	.slick-prev{
	  		position: absolute;
		    top: 0px;
		    height:w(520px);
		    width:10%;
		    z-index: 10;
		    left:0;
		    opacity: 0;
	  	}
	  	.slick-next{
	  		position: absolute;
		    top: 0px;
		    right: 0;
		    height:w(520px);
		    width:10%;
		    z-index: 10;
		    opacity: 0;
	  	}
	}
	.detail_more{

		width:648px;
		margin:0 auto 120px;
		p.detail_more_text{

			margin-bottom:40px;
			font-size:12px;
			//line-height: 1.833333;
			line-height:2;
		}
		a.detail_more_tag{
			font-size:10px;
			line-height: 1.8;
			display: inline-block;
			background:#fff;
			padding-left: 5px;
			padding-right: 5px;
		}

	}
	.detail_data{
		width:648px;
		margin:0 auto 160px;
		h4{
			font-weight: bold;
			font-size:10px;
			margin-bottom: 20px;
		}
		p{
			font-size:11px;
			line-height: 1.8;
			margin-bottom: 40px;
			&:last-child{
				margin-bottom:0;
			}
			&.detail_data_link{
				margin-bottom: 20px;
			}
			a{

				font-size:10px;
				line-height: 1.8;
				display: inline-block;
				background-color:#fff;
				padding-left: 5px;
				padding-right: 5px;
				&:hover{
					background-color:rgba(255,255,255,0.8);
				}
			}
		}
	}
	.detail-nav{
		//width:w(1146px);
		margin:0 w(58px);
		display: flex;
		    justify-content: space-between;
		    div{
		    	a{
		    		font-size:11px;
		    	}
		    }
		    .detail-nav-prev{
		    	display: inline-block;
		    	width: 40px;
            height: 25px;
	            //background: orange;
	            position: relative;
	            &:before{
					position: absolute;
					left:0px;
					top:10px;
					height:1px;
					width:40px;
					background: #000;
					content:'';
					transform: rotate(15deg);
					transition:all 0.3s ease-in-out;
					transform-origin: 0% 0;
				}
				&:after{
					position: absolute;
					left:0px;
					top:10px;
					height:1px;
					width:40px;
					background: #000;
					content:'';
					transform: rotate(-15deg);
					transition:all 0.3s ease-in-out;
					transform-origin: 0% 0;
				}
		    }
		    .detail-nav-next{
		    	display: inline-block;
		    	width: 40px;
            height: 25px;
            //background: orange;
            position: relative;
            &:before{
				position: absolute;
				left:0px;
				top:10px;
				height:1px;
				width:40px;
				background: #000;
				content:'';
				transform: rotate(15deg);
				transition:all 0.3s ease-in-out;
				transform-origin: 100% 0;
			}
			&:after{
				position: absolute;
				left:0px;
				top:10px;
				height:1px;
				width:40px;
				background: #000;
				content:'';
				transform: rotate(-15deg);
				transition:all 0.3s ease-in-out;
				transform-origin: 100% 0;
			}
		    }

	}
}
}

@media screen and (max-width:960px){
.detail{
	background:#F5F5F5;
	    padding-top: sw(105px);
	.detail_visual{
		height: 56.25vw;
		margin-bottom: sw(80px);
	}
	.detail_title{
		width:auto;
		margin:0 sw(40px) sw(40px);
		h1{
			font-size:sw(15px);
			font-weight: bold;
			margin-bottom: sw(11px);
		}
		h2{
			font-size:sw(15px);
			line-height: 1.833333;
		}
	}
	.detail_text{
		width:auto;
		margin:0 sw(40px) sw(40px);
		h3{
			font-size:sw(12px);
			font-weight: bold;
			margin-bottom: 1vw;
			line-height: 1.833333;

		}
		p{
			font-size:sw(12px);
			line-height: 2;
		}
	}

	iframe{
		width:sw(295px);
		height:sw(166px);
		margin:0 sw(40px) sw(10px);
		display: block;
	}

	.detail_image{
		width:auto;
		margin:0 sw(40px) sw(40px);
		img{
			margin-bottom: sw(10px);
			width:100%;
		}
		p{
			color:#777777;
			font-size:sw(10px);
		}
	}
	.detail-list{
		padding:0 0 0 sw(20px);
		margin-bottom:sw(40px);
		overflow: hidden;
		.slick-list{
			overflow: visible;
		}
		img{
			width:auto;
			//width:sw(237px);
			height: sw(135px);
			margin-left:sw(20px);
			margin-bottom: sw(10px);
		}
		p{
			margin-left:sw(20px);
			color:#777777;
			font-size:sw(10px);

		}

	  	.slick-prev{
	  		position: absolute;
		    top: 0px;
		    height:sw(135px);
		    width:0%;
		    z-index: 10;
		    left:0;
		    opacity: 0;
	  	}
	  	.slick-next{
	  		position: absolute;
		    top: 0px;
		    right: 0;
		    height:sw(135px);
		    width:0%;
		    z-index: 10;
		    opacity: 0;
	  	}
	}
	.detail_more{

		width:auto;
		margin:0 sw(40px) sw(65px);
		p.detail_more_text{

			margin-bottom:sw(40px);
			font-size:sw(12px);
			line-height: 2;
		}
		a.detail_more_tag{
			font-size:sw(10px);
			line-height: 1.8;
			display: inline-block;
			background:#fff;
			padding-left: sw(5px);
			padding-right: sw(5px);
		}

	}
	.detail_data{
		width:auto;
		margin:0 sw(40px) sw(65px);
		h4{
			font-weight: bold;
			font-size:sw(10px);
			margin-bottom: sw(20px);
		}
		p{
			font-size:sw(10px);
			line-height: 1.8;
			margin-bottom: sw(40px);
			&:last-child{
				margin-bottom:0;
			}
			&.detail_data_link{
				margin-bottom: sw(20px);
			}
			a{

				font-size:sw(10px);
				line-height: 1.8;
				display: inline-block;
				background:#fff;
				padding-left: sw(5px);
				padding-right: sw(5px);
			}
		}
	}
	.detail-nav{
		width:auto;
		margin:0 sw(40px);
		display: flex;
		    justify-content: space-between;
		    div{
		    	a{
		    		font-size:sw(11px);
		    	}
		    }
		    .detail-nav-prev{
		    	display: inline-block;
		    	width: sw(40px);
            	height: sw(25px);
	            //background: orange;
	            position: relative;
	            &:before{
					position: absolute;
					left:sw(0px);
					top:sw(10px);
					height:1px;
					width:sw(40px);
					background: #000;
					content:'';
					transform: rotate(15deg);
					transition:all 0.3s ease-in-out;
					transform-origin: 0% 0;
				}
				&:after{
					position: absolute;
					left:sw(0px);
					top:sw(10px);
					height:1px;
					width:sw(40px);
					background: #000;
					content:'';
					transform: rotate(-15deg);
					transition:all 0.3s ease-in-out;
					transform-origin: 0% 0;
				}
		    }
		    .detail-nav-next{
		    	display: inline-block;
		    	width: sw(40px);
            height: sw(25px);
            //background: orange;
            position: relative;
            &:before{
				position: absolute;
				left:sw(0px);
				top:sw(10px);
				height:1px;
				width:sw(40px);
				background: #000;
				content:'';
				transform: rotate(15deg);
				transition:all 0.3s ease-in-out;
				transform-origin: 100% 0;
			}
			&:after{
				position: absolute;
				left:sw(0px);
				top:sw(10px);
				height:1px;
				width:sw(40px);
				background: #000;
				content:'';
				transform: rotate(-15deg);
				transition:all 0.3s ease-in-out;
				transform-origin: 100% 0;
			}
		    }

	}
}
}

