@media screen and (min-width:960px) {
    .about {
        background: #F5F5F5;
        padding-top: 180px;

        h1 {
            font-size: 94px;
            text-align: left;
            font-weight: 700;
            width: 900px;
            margin: 0 auto;
            margin-bottom: 27px;
            line-height: 1.32978723404;
                letter-spacing: 1px;
            br {
                display: none;
            }
        }

        .about-h1-text {
            width: 900px;
            margin: 0 auto;
            p {
                font-weight: bold;
                text-align: left;
                //line-height: 1.7777;
                line-height: calc(69 / 37);
                font-size: 37px;
                letter-spacing: 1px;
                padding-bottom: 58px;
                font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic",-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue,  Verdana, Meiryo, sans-serif;

            }
        }
        .about_about{
            width: 900px;
            margin: 0 auto;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .about_word {
            position: relative;
            margin-top:15px;
            transform:scale(0.92);
            .overlap{
                    position: absolute;
    top: 204px;
    left: 122px;
    height: 32px;
                opacity: 0;
                transition: opacity linear 0.8s;
                &.-show{
                    opacity: 1;
                }
            }
            ul {
                display: flex;
                justify-content: center;
                margin-right: 112px;
                li {
                    display: inline-block;
                    width: 236px;
                    height: 236px;
                    line-height: 236px;
                    text-align: center;
                    //border-radius: 50%;
                    //border:1px solid #000;
                    font-size: 15px;
                    margin: 0 -72px;

                    &:nth-child(1),
                    &:nth-child(3) {
                        margin-top: 160px;
                    }

                    position: relative;

                    svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: rotate(-90deg);
                    }

                    circle {
                        fill: transparent;
                        stroke: #000;
                        stroke-width: 1;
                        stroke-dasharray: 0 760;
                        //animation: circle 2s 1;
                    }

                    p {
                        transform: translate(0, 10px);
                        opacity: 0;
                        transition: all cubic-bezier(.23, 1, .32, 1) 0.8s 0.6s;
                    }

                    &:nth-child(1) p{
                        position: relative;
                        right: 10px;
                    }
                    &:nth-child(3) p{
                        position: relative;
                        right: -10px;
                    }
                    &.-show {
                        p {
                            transform: translate(0, 0);
                            opacity: 1;
                        }

                        circle {
                            animation: circle 1.2s 1 cubic-bezier(.23, 1, .32, 1);
                            animation-fill-mode: forwards;
                        }
                    }
                }

            }

            margin-bottom: 80px;
        }

        .about-message {
            p {
                text-align: left;
                line-height: calc(37 / 18);
                font-size: 18px;
            }

            .about-message-text {
                margin-bottom: 55px;
                text-align: left;
            }

            .about-message-name {
                text-align: left;
            }

            margin-bottom: 190px;
        }

        h2 {
            font-size: 15px;
            text-align: center;
            margin-bottom: 103px;
        }

        .about-client {
            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 900px;
                margin: 0 auto;

                li {
                    display: inline-block;
                    margin: 0 30px 40px;
                    width: 150px;
                    height: 80px;
                    //border:1px solid #ccc;
                    position: relative;

                    img {
                        width: 100%;
                        position: relative;
                        top: 50%;
                        transform: translate(0, -50%);
                    }
                }

                span {
                    display: inline-block;
                    width: 150px;
                    margin: 0 30px;
                }
            }

            padding-bottom: 100px;
        }

        .about-member {
            h3 {
                text-align: center;
                //display: none;
                margin-bottom: w(80);
                padding-top: w(80);
            }

            ul.about-member-officer {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: w(1146px);
                max-width: 1146px;
                margin: 0 auto;

                li {
                    display: inline-block;
                    width: w(315px);
                    max-width: 315px;
                    margin-bottom: 88px;
                }
            }

            ul.about-member-staff {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: w(1146px);
                max-width: 1146px;
                margin: 0 auto;

                li {
                    display: inline-block;
                    width: w(415px);
                    max-width: 415px;
                    margin-bottom: 40px;
                    &:nth-child(3n){
                        width: w(315px);
                        max-width: 315px;

                    }
                    &.about-detail{
                        cursor: none;
                        transition:0.2s ease-out all;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }

                span {
                    display: inline-block;
                    width: w(240px);
                    max-width: 240px;
                }
            }

            ul.-column2 {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: w(1146px);
                max-width: 1146px;
                margin: 0 auto;

                li {
                    display: inline-block;
                    width: w(573px);
                    max-width: 573px;
                    padding-right: 100px;
                    margin-bottom: 40px;
                }
            }
            .about-member-img{
                 width: w(315px);
                max-width: 315px;
                img{
                    width: 100%;
                }
                position: relative;
                img:nth-child(2){
                    width: w(20);
                    position: absolute;
                    bottom:w(10);
                    right:w(10);
                }
                margin-bottom: 30px;
            }
             .about-member-seimei {
                font-size: 20px;
                line-height: 1.1;
                margin-bottom: 10px;

            }
            .about-member-name {
                font-size: 14px;
                line-height: 1;
                margin-bottom: 20px;

            }

            .about-member-position {
                font-size: 10px;
                line-height: 1;
                margin-bottom: 20px;
                br{
                    display: none;
                }
            }

            .about-member-text {
                font-size: 13px;
                line-height: 2;
                margin-bottom: 20px;
                //padding-right: w(100px);
            }

            .about-member-link {
                a {
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.8);
                    }

                    height: 15px;
                    background-color: #fff;
                    font-size: 10px;
                    line-height: 15px;
                    padding:0 5px;
                    display: inline-block;
                    color:#000;
                    text-decoration: none;
                }

                margin-bottom: 20px;
            }

            .about-member-sns {
                a {
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.8);
                    }

                    height: 15px;
                    background-color: #fff;
                    font-size: 10px;
                    line-height: 15px;
                    padding:0 5px;
                    display: inline-block;
                    color:#000;
                    text-decoration: none;
                    margin-right: 6px;
                }
            }
        }
    }

.about_layer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;

    .about_layer_image {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //height: h(590px);
            width: w(630);
            max-width: 1260px;

        img {
            //height: h(368px);
            //width: auto;
            //max-width: unset;
            width: w(630);
            max-width: 1260px;
            margin-bottom: h(40);
        }

        .about-layer-seimei{
            font-size: h(20px);
            color: #fff;
            margin-bottom: h(10);
        }
        .about-layer-name{
            font-size: h(14px);
            margin-bottom: h(10);
            color: #fff;
        }
        .about-layer-position{
            font-size: h(10px);
            margin-bottom: h(24);
            color: #fff;
        }
        .about-layer-description{
            font-size: h(10px);
            color: #fff;
            line-height: 2;
        }

    }
    .about_layer_close {
        width: 40px;
        height: 20px;
        display: inline-block;
        position: absolute;
        top: 50px;
        right: 60px;
        //transform: translate(-50%, 0);
        cursor: pointer;

        &:before {
            position: absolute;
            left: -3px;
            top: 10px;
            height: 1px;
            width: 46px;
            background: #fff;
            content: '';
            transform: rotate(25deg);
            transition: all 0.3s ease-in-out;
        }

        &:after {
            position: absolute;
            left: -3px;
            top: 10px;
            height: 1px;
            width: 46px;
            background: #fff;
            content: '';
            transform: rotate(-25deg);
            transition: all 0.3s ease-in-out;
        }

        &:hover {
            &:before {
                transform: rotate(-25deg);
            }

            &:after {
                transform: rotate(25deg);
                //transform: rotate(-25deg);
            }
        }
    }
}


}

@keyframes circle {
    0% {
        stroke-dasharray: 0 760;
    }

    99.9%,
    to {
        stroke-dasharray: 760 760;
    }
}

@media screen and (max-width:960px) {
    .about {
        background: #F5F5F5;
        padding-top: sw(171px);

        h1 {
            //font-size: sw(32px);
            margin-left: sw(40px);
            font-size: 12vw;
line-height: 1.4;
            text-align: left;
            font-weight: 700;
            margin-bottom: sw(35px);
        }

        .about-h1-text {
            p {
                font-weight: bold;
                text-align: left;
                line-height: 2;
                font-size: 4.5vw;
                margin-left: sw(40px);
            }
        }

        .about_word {
            position: relative;
            .overlap{
                position: absolute;
                top: sw(107px);
                left: sw(173px);
                width: sw(30px);
                opacity: 0;
                transition: opacity linear 0.8s;
                &.-show{
                    opacity: 1;
                }
            }
            ul {
                display: flex;
                justify-content: center;

                li {
                    display: inline-block;
                    width: sw(136px);
                    height: sw(136px);
                    line-height: sw(136px);
                    text-align: center;
                    border-radius: 50%;
                    //border:1px solid #000;
                    font-size: sw(10px);
                    margin: 0 sw(-42px);

                    &:nth-child(1),
                    &:nth-child(3) {
                        margin-top: sw(83px);
                    }

                    /*&:nth-child(1),&:nth-child(3){
						margin-top: 160px;
					}*/
                    position: relative;

                    svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        transform: rotate(-90deg);
                    }

                    circle {
                        fill: transparent;
                        stroke: #000;
                        stroke-width: 1;
                        stroke-dasharray: 0 760;
                        //animation: circle 2s 1;
                    }

                    p {
                        transform: translate(0, 10px);
                        opacity: 0;
                        transition: all cubic-bezier(.23, 1, .32, 1) 0.8s 0.6s;
                    }
                    &:nth-child(1) p{
                        position: relative;
                        right: 2vw;
                    }
                    &:nth-child(3) p{
                        position: relative;
                        right: -2vw;
                    }
    
                    &.-show {
                        p {
                            transform: translate(0, 0);
                            opacity: 1;
                        }

                        circle {
                            animation: circle 1.2s 1 cubic-bezier(.23, 1, .32, 1);
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }

            margin-bottom: sw(75px);
        }

        .about-message {
            p {
                width: auto;
                margin: 0 sw(39.5px);
                text-align: center;
                line-height: calc(22 / 12);
                font-size: sw(12px);
            }

            .about-message-text {
                margin-bottom: sw(40px);
                text-align: left;
            }

            .about-message-name {
                text-align: left;
                font-size: sw(12px);
            }

            margin-bottom: sw(120px);
        }

        h2 {
            font-size: sw(15px);
            text-align: center;
            margin-bottom: sw(80px);
        }

        .about-client {
            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    display: inline-block;
margin: 0;
width: 48vw;
text-align: center;
margin-bottom: 5vw;
                    &.-nomargin{
                        margin-bottom: 0;
                    }
                    img {
                        width: 30vw;
                    }
                }

                span {
                    display: inline-block;
                    width: sw(180px);
                }
            }

            padding-bottom: sw(80px);
        }

        .about-member {
            h3 {
                font-size: sw(15px);
                text-align: left;
                margin-left: sw(40px);
                margin-bottom: sw(40px);
            }

            ul.about-member-officer {
                display: block;
                justify-content: center;
                flex-wrap: wrap;
                width: auto;
                margin: 0 auto;
                padding-bottom: sw(12px);

                li {
                    margin-left: sw(40px);
                    display: inline-block;
                    width: auto;
                    margin-bottom: sw(62px);
                }

                .about-member-name {
                    font-size: sw(20px);
                    margin-bottom: sw(20px);

                }

                .about-member-position {
                    font-size: sw(10px);
                    line-height: 1.2;
                    margin-bottom: sw(20px);
                }

                .about-member-text {
                    font-size: 3.2vw;
                    line-height: 2;
                    margin-bottom: sw(20px);
                    padding-right: sw(40px);
                }

                .about-member-sns {

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    	margin-right: sw(6px);
                    }
                }
                .about-member-link {

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    	margin-bottom: sw(20px);
                    }
                }
            }

            ul.about-member-staff {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: sw(295px);
                margin: 0 auto;
                padding-bottom: sw(80px);

                li {
                    width: sw(130px);
                    margin-bottom: sw(40px);
                }
                span{
                    width: sw(130px);
                }

                .about-member-img{
                    width: sw(130px);
                    img{
                        width: 100%;
                    }
                    position: relative;
                    img:nth-child(2){
                        width: sw(15);
                        position: absolute;
                        bottom:sw(5);
                        right:sw(5);
                    }
                    margin-bottom: sw(15px);
                }
                 .about-member-seimei {
                    font-size: sw(15px);
                    line-height: 1.1;
                    margin-bottom: sw(10px);

                }

                .about-member-name {
                    font-size: sw(12px);
                    line-height: 1.2;
                    margin-bottom: sw(10px);

                }

                .about-member-position {
                    font-size: 2vw;
                    line-height: 1.2;
                    padding-right: 5vw;
                }

                .about-member-text {
                    font-size: 3.2vw;
                    line-height: 2;
                    padding-right: sw(40px);
                }

                .about-member-sns {

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    	margin-right: sw(6px);
                    }
                }
                .about-member-link {
                    margin-top: 3vw;

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    }
                }
            }

            ul.-column2 {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: auto;
                margin: 0 auto;

                li {
                    margin-left: sw(40px);
                    display: inline-block;
                    width: auto;
                    margin-bottom: sw(88px);
                }


                

                .about-member-name {
                    font-size: sw(12px);
                    margin-bottom: sw(20px);

                }
                 .about-member-seimei {
                    font-size: sw(15px);
                    line-height: 1.1;
                    margin-bottom: sw(10px);

                }
                .about-member-position {
                    font-size: sw(8px);
                    line-height: 1.2;
                    margin-bottom: sw(20px);
                }

                .about-member-text {
                    font-size: sw(10px);
                    line-height: 2;
                    margin-bottom: sw(20px);
                    padding-right: sw(40px);
                }

                .about-member-sns {

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    	margin-right: sw(6px);
                    }
                }
                .about-member-link {

                    a {
                        color: #000;
                        text-decoration: none;
	                    height: sw(15px);
	                    background: #fff;
	                    font-size: sw(10px);
	                    line-height: sw(15px);
	                    padding: 0 sw(5px);
	                    display: inline-block;
                    	margin-bottom: sw(20px);
                    }
                }
            }



        }
    }
    .about_layer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    background: rgba(119, 119, 119, 0.9);
    display: none;

    .about_layer_image {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: sw(295px);

        img {
            height: auto;
            width: sw(295px);
            max-width: unset;
            margin-bottom: sw(25px);
        }

        /*
        p {
            font-size: sw(10px);
            margin-top: sw(10px);
            color: #fff;
            font-weight: bold;
        }*/

        

        .about-layer-seimei{
            font-size: sw(20px);
            margin-bottom: sw(10px);
            color: #fff;
        }
        .about-layer-name{
            font-size: sw(14px);
            margin-bottom: sw(16px);
            color: #fff;
        }
        .about-layer-position{
            font-size: sw(10px);
            margin-bottom: sw(25px);
            color: #fff;
        }
        .about-layer-description{
            font-size: sw(10px);
            line-height: 2;
            margin-bottom: sw(10px);
            color: #fff;
        }
    }
    .about_layer_close {
            width: sw(40px);
            height: sw(20px);
            display: inline-block;
            position: absolute;
            top: sw(45px);
            right: sw(30px);
            cursor: pointer;

            &:before {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #fff;
                content: '';
                transform: rotate(25deg);
                transition: all 0.3s ease-in-out;
            }

            &:after {
                position: absolute;
                left: sw(-3px);
                top: sw(10px);
                height: 1px;
                width: sw(46px);
                background: #fff;
                content: '';
                transform: rotate(-25deg);
                transition: all 0.3s ease-in-out;
            }
        }
}

}