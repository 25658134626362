
@media screen and (min-width:960px){
	.error{
		height: 100vh;
	  a{
	  	display: block;
	  	position: absolute;
	  	top:50%;
	  	left:50%;
	  	transform:translate(-50%,-50%);
	  	text-align: center;
	  	img{
	  		margin-bottom:78px;
	  		width:595px;
	  	}
	  	h1{
	  		font-size: 22px;
	  		margin-bottom:20px;
	  		font-weight: bold;
	  	}
	  	p{
	  		font-size:12px;
	  	}
	  }
	}
}
@media screen and (max-width:960px){
	.error{
		height: 100vh;
	  a{
	  	display: block;
	  	position: absolute;
	  	top:50%;
	  	left:50%;
	  	transform:translate(-50%,-50%);
	  	text-align: center;
	  	img{
	  		margin-bottom:sw(64.3px);
	  		width:sw(249px);
	  	}
	  	h1{
	  		font-size: sw(22px);
	  		margin-bottom:sw(20px);
	  		font-weight: bold;
	  		white-space: nowrap;
	  	}
	  	p{
	  		font-size:sw(12px);
	  	}
	  }
	}
}