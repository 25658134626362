/* ==========================================================================
 *
 * Blocks - .footer
 *
========================================================================== */
.topPage{
	.footer_pagetop_fixed{
		display: none;
	}
}
@media screen and (min-width:960px){
	.topPage{
		footer {
			display: none;
		}
	}
footer {
	background:#F5F5F5;
	//border-top:240px solid #eee;
	padding-top: 200px;
	padding-bottom: 115px;
	text-align:center;
	h1{
		    mix-blend-mode: difference;
		img{
			width:171px;
			height:25.5px;
		}
		margin-bottom:80px;
	}
	ul{
		display: flex;
		justify-content: space-between;
		width:128px;
		margin:0 auto;
		li{
			&:nth-child(1){
				img{
					width:23.5px;
					height:19px;
				}
			}
			&:nth-child(2){
				img{
					width:16.3px;
					height:18.3px;
				}
			}
			&:nth-child(3){
				img{
					width:20px;
					height:20px;
				}
			}

		}
		margin-bottom:80px;
	}
	p{
		font-size:11px;
	}
	position: relative;
	.footer_pagetop{
		display: none;
	}
}
}
.footer_pagetop_fixed{
	position: fixed;
	bottom: 60px;
	right:w(62px);
	width:40px;
	height:20px;
	cursor: pointer;
	span{
		display: block;
		position: absolute;
		&:nth-child(1){
			top:0;
			left:19.5px;
			width:1px;
			height: 20px;
			background:#000;
			transition:all ease-in-out 0.3s;
		}
		&:nth-child(2){
			top:0;
			left:19.5px;
			width:1px;
			height: 20px;
			background:#000;
			transform-origin: 0.5px 0;
			transform:rotate(60deg);
			transition:all ease-in-out 0.3s;
		}
		&:nth-child(3){
			top:0;
			left:19.5px;
			width:1px;
			height: 20px;
			background:#000;
			transform-origin: 0.5px 0;
			transform:rotate(-60deg);
			transition:all ease-in-out 0.3s;
		}
	}
	&:hover span{
		&:nth-child(1){
			top:-10px;
			height: 30px;
		}
		&:nth-child(2){

			top:-10px;
			transform:rotate(30deg);
		}
		&:nth-child(3){

			top:-10px;
			transform:rotate(-30deg);
		}
	}
}
@media screen and (max-width:960px){
.footer_pagetop_fixed{
display: none;
}
.topPage{
	footer {
		display: none;
	}
}
footer {
	background:#F5F5F5;
	//background:green;
	//border-top:240px solid #eee;
	padding-top: sw(144px);
	padding-bottom: sw(115px);
	text-align:center;
	h1{
		    mix-blend-mode: difference;
		img{
			width:sw(171px);
			height:sw(25.5px);
		}
		margin-bottom:sw(80px);
	}
	ul{
		display: flex;
		justify-content: space-between;
		width:sw(128px);
		margin:0 auto;
		li{
			&:nth-child(1){
				img{
					width:sw(23.5px);
					height:sw(19px);
				}
			}
			&:nth-child(2){
				img{
					width:sw(16.3px);
					height:sw(18.3px);
				}
			}
			&:nth-child(3){
				img{
					width:sw(20px);
					height:sw(20px);
				}
			}

		}
		margin-bottom:sw(80px);
	}
	p{
		font-size:sw(11px);
	}
	position: relative;
	.footer_pagetop{
		position: absolute;
		top:sw(65px);
		right:sw(30px);
		width:sw(40px);
		height:sw(20px);
		cursor: pointer;
		span{
			display: block;
			position: absolute;
			&:nth-child(1){
				top:0;
				left:sw(19.5px);
				width:1px;
				height: sw(20px);
				background:#000;
				transition:all ease-in-out 0.3s;
			}
			&:nth-child(2){
				top:0;
				left:sw(19.5px);
				width:1px;
				height: sw(20px);
				background:#000;
				transform-origin: 0.5px 0;
				transform:rotate(60deg);
				transition:all ease-in-out 0.3s;
			}
			&:nth-child(3){
				top:0;
				left:sw(19.5px);
				width:1px;
				height: sw(20px);
				background:#000;
				transform-origin: 0.5px 0;
				transform:rotate(-60deg);
				transition:all ease-in-out 0.3s;
			}
		}
	}
}
}