@use "sass:math";

/*
@function w($_size, $_base-width: 1440px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width)) * 100vw;
  }
  @return $size;
}

@function s($size) {
  @return strip-unit($size) * 1px;
}

@function sw($_size, $_base-width: 750px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width)) * 100vw;
  }
  @return $size;
}
*/

@function sw($_size, $_base-width: 375px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width))*100vw;
  }
  @return $size;
}
@function sh($_size, $_base-height: 1340px) {
  $size: $_size;
  @if ($_base-height != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-height))*100vh;
  }
  @return $size;
}

@function pw($_size, $_base-height: 1340px) {
  $size: $_size;
  @if ($_base-height != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-height))*100vw;
  }
  @return $size;
}


@function h($_size, $_base-height: 800px) {
  $size: $_size;
  @if ($_base-height != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-height))*100vh;
  }
  @return $size;
}
@function w($_size, $_base-width: 1280px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width))*100vw;
  }
  @return $size;
}

@function s($_size, $_base-width: 1px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width))*1px;
  }
  @return $size;
}

@function t($_size, $_base-width: 1920px) {
  $size: $_size;
  @if ($_base-width != false) {
    $size: math.div(strip-unit($size) , strip-unit($_base-width))*100vw;
  }
  @return $size;
}



// Sass の math モジュールを読み込む

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number , ($number * 0 + 1));
  }

  @return $number;
}