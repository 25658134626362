
.base{
	@media #{$mq-pc} {
		height: w(10);
		@include clearfix();
		transform:rotate(45deg);
	}
	@media #{$mq-sp} {

	}
}
