@media screen and (min-width:960px){
.careers{
	background:#F5F5F5;
	padding-top:w(229px);
	.careers_visual{
		/*
		height:282px;
		background:url(../img/careers_visual.png) no-repeat center;
		background-size:cover;
		*/
		padding-left:w(680px);
		.slick-list{
			overflow: visible;
		}
		.careers_visual-element{
			padding-right:w(80px);
			position: relative;
			height:w(282px);
			img.-type1{
				width:w(400px);
				position: relative;
				top:50%;
				transform:translate(0,-50%);
			}
			img.-type2{
				width:w(282px);
				position: relative;
				top:50%;
				transform:translate(0,-50%);
			}

		}
		margin-bottom: 118px;
		
	}
	h1{
		font-size:22px;
		text-align: center;
		font-weight: 700;
		margin-bottom: 70px;
	}
	/*
	h2{
		font-size:12px;
		text-align: center;
		margin-bottom: 70px;
	}*/
	.careers-h1-text{
		p{
			text-align: center;
			line-height:2;
			font-size: 15px;
			padding-bottom: 127px;
		}
	}
	
	.careers-list{
		width:w(980px);
		//max-width: 980px;
		margin:0 auto 80px;
		border-top:1px solid #000;
		ul{
			li{
				cursor: pointer;
				position: relative;
				min-height: 160px;
				width:100%;
				border-bottom:1px solid #000;
				.careers-list-name{
					font-size:16px;
					line-height: 1.8;
					margin-bottom:40px;
					width: w(400px);
					position: absolute;
					top:66px;
					br{
						display: none;
					}
				}
				.careers-list-text{
					padding-top:66px;
					padding-bottom:66px;
					font-size:14px;
					width: w(482px);
					margin-left:w(332px);
					line-height: 1.8;
					max-height: 0;
					opacity: 0;
					overflow: hidden;
					transition:all 0.5s ease-in;
				}
				&.-show{
					.careers-list-text{
						max-height: 2000px;
						opacity: 1;
					}
					.careers-list-btn{
						&:after{
							height: 0px;
							top:10px;
						}
					}
				}
				&:hover{
					.careers-list-btn{
						&:after{
							height: 10px;
							top:5px;
						}
					}
				}
				.careers-list-btn{
					position: absolute;
					right: 0;
					top:70px;
					height:20px;
					width: 40px;
					display: block;
					cursor:pointer;
					&:before{
						content:'';
						position: absolute;
						top:10px;
						left:0;
						width:40px;
						height: 1px;
						background:#000;
					}
					&:after{
						content:'';
						position: absolute;
						top:0px;
						left:20px;
						width:1px;
						height: 20px;
						background:#000;
					transition:all 0.5s ease-in-out;
					}
				}

			}
		}
	}
	.careers-essential{
		width:w(980px);
		margin:0 auto 140px;
		position: relative;
		h3{
			font-weight: 700;
			font-size:15px;
			position: absolute;
			top:0;
		}
		dl{
			margin-left: w(332px);
			dt{
				float:left;
				clear: both;
				width:w(70px);
				margin-right: w(100px);
				font-size: 14px;
				line-height: 2;
			}
			dd{
				float:left;
				width:w(400px);
				font-size: 14px;
				line-height: 2;
				margin-bottom: 22px;
			}
			&:after{
				content:'';
				display: block;
				clear: both;
			}
		}
	}
	.careers-contact{
		width:auto;
		margin:0 w(58px);
		background:#fff;
		text-align: center;
		padding-top:76px;
		padding-bottom:76px;
		.careers-contact-lead{
			font-size:12px;
			margin-bottom:52px;
		}
		.careers-contact-address{
			a{
				color:#000;
				font-weight:bold;
				font-size:22px;
				text-decoration: none;
			}
		}
	}
}
}
@media screen and (max-width:960px){
.careers{
	background:#F5F5F5;
	padding-top:35vw;
	/*
	.careers_visual{
		height:sw(282px);
		background:url(../img/careers_visual.png) no-repeat 31%;
		background-size:cover;
		margin-bottom: sw(143px);
	}*/
	.careers_visual{
		/*
		height:282px;
		background:url(../img/careers_visual.png) no-repeat center;
		background-size:cover;
		*/
		width: 100%;
    	overflow: hidden;
		//padding-left:w(367px);
		.slick-list{
			overflow: visible;
		}
		.careers_visual-element{
			//padding-right:sw(80px);
			position: relative;
			height:sw(282px);
			img.-type1{
				width:sw(375px);
				position: relative;
				top:50%;
				transform:translate(0,-50%);
			}
			
			img.-type2{
				width:sw(375px);
				position: relative;
				top:50%;
				transform:translate(0,-50%);
			}

		}
		margin-bottom: 70px;
		
	}
	h1{
		font-size:sw(22px);
		text-align: center;
		font-weight: 700;
		//margin-bottom: 3vw;
		margin-bottom: 15vw;
	}
	/*
	h2{
		font-size:sw(12px);
		text-align: center;
		margin-bottom: 15vw;
	}*/
	.careers-h1-text{
		p{
			text-align: center;
			line-height:2.333333;
			font-size: sw(15px);
			padding-bottom: 15vw;
		}
	}
	
	.careers-list{
		width:sw(294px);
		margin:0 auto sw(80px);
		border-top:1px solid #000;
		ul{
			li{
				cursor: pointer;
				position: relative;
				min-height: sw(160px);
				width:100%;
				border-bottom:1px solid #000;
				.careers-list-name{
					font-size:4.5vw;
					line-height: 1.4;
					margin-right: sw(80px);
					margin-bottom:sw(40px);
					width: auto;
					position: absolute;
					top:sw(80px);
					span{
						display: block;
						position: relative;
						top:50%;
						transform:translate(0,-50%);
					}
				}
				.careers-list-text{
					padding-top:sw(132px);

					padding-bottom:sw(0px);
					font-size: sw(15px);
					width: auto;
					line-height: 2;
					max-height: 0;
					opacity: 0;
					overflow: hidden;
					transition:all 0.5s ease-in-out;
				}
				&.-show{
					.careers-list-text{

						padding-top:sw(172px);
					padding-bottom:sw(66px);
						max-height: sw(2400px);
						opacity: 1;
					}
					.careers-list-btn{
						&:after{
							height: 0px;
							top:sw(10px);
						}
					}
				}
				.careers-list-btn{
					position: absolute;
					right: 0;
					top:sw(70px);
					height:sw(20px);
					width: sw(40px);
					display: block;
					cursor:pointer;
					&:before{
						content:'';
						position: absolute;
						top:sw(10px);
						left:0;
						width:sw(40px);
						height: 1px;
						background:#000;
					}
					&:after{
						content:'';
						position: absolute;
						top:0px;
						left:sw(20px);
						width:1px;
						height: sw(20px);
						background:#000;
					transition:all 0.5s ease-in-out;
					}
				}
			}
		}
	}
	.careers-essential{
		width:auto;
		margin:0 sw(40px) sw(38px);
		position: relative;
		h3{
			font-weight: 700;
			font-size:sw(15px);
			margin-bottom:sw(35px);
		}
		dl{
			dt{
				float:left;
				clear: both;
				width:sw(83px);
				font-size: sw(13px);
				line-height: 2;
			}
			dd{
				float:left;
				width:sw(212px);
				font-size: sw(12px);
				line-height: 1.8333;
				margin-bottom: sw(22px);
			}
			&:after{
				content:'';
				display: block;
				clear: both;
			}
		}
	}
	.careers-contact{
		width:auto;
		margin:0 sw(40px);
		background:#fff;
		text-align: center;
		padding-top:sw(46px);
		padding-bottom:sw(106px);
		.careers-contact-lead{
			font-size:sw(12px);
			line-height: 1.5;
			margin:0 sw(46px) sw(60px);
		}
		.careers-contact-address{
			a{
				color:#000;
				font-weight:bold;
				font-size:sw(22px);
				text-decoration: none;
			}
		}
	}
}
}

